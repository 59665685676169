import cn from "classnames";
import ReactStepper from "@keyvaluesystems/react-stepper";
import {MediaQueryTypes, useMediaQuery} from "../../app/hooks";

interface StepperProps {
    readonly steps: {
        readonly id: number;
        readonly title: string;
    }[];
    readonly currentStepIndex: number;
}

interface Step {
    readonly stepLabel: string;
}

export function Stepper({steps, currentStepIndex}: StepperProps) {
    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);
    
    return (
        <div className={"d-flex flex-row"}>
            <ReactStepper
                steps={
                    steps.map((step, index) => {
                        return {
                            stepLabel: step.title,
                            completed: false,
                        };
                    })
                }
                currentStepIndex={currentStepIndex}
                orientation={"horizontal"}
                labelPosition={"bottom"}
                styles={{
                    Node: (step: Step, stepIndex: number) => ({
                        height: '3rem',
                        width: '3rem',
                        fontSize: '1.2rem',
                    }),
                    InactiveLineSeparator: (step: Step, stepIndex: number) => ({
                        width: isMobile ? '1rem' : '4rem',
                    }),
                    LabelTitle: (step: Step, stepIndex: number) => {
                        const hideStepperLabels = isMobile && stepIndex !== currentStepIndex;
                        return ({
                            display: hideStepperLabels ? 'none' : 'inline',
                        });
                    }
                }}
            />
        </div>
    )
}