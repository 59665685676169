import "./DefaultConfigurationEntry.css";
import {ProductConfigurationField} from "./ProductConfigurationInputProps";
import {FormInputField} from "../../components/forms/FormInputField";
import {fieldModifierAttributes} from "../FieldModifiers";
import {useFormContext} from "react-hook-form";
import {shouldRevalidate} from "../validation/utils";
import {partial} from "lodash";

export const DefaultConfigurationEntry: ProductConfigurationField =
	({config, onFieldBlur = () => void 0}) => {

		const {trigger, getFieldState} = useFormContext();

		const revalidate = async () => {
			if (shouldRevalidate(getFieldState(config.configurationKey))) {
				await trigger(config.configurationKey);
			}
		}

		return (
			<FormInputField name={config.configurationKey}
			                label={config.label}
			                type="text"
			                inputMode={config.inputType.name === "Numeric" ? "numeric" : undefined}
			                onChange={async value => {
				                await revalidate();
				                return value;
			                }}
			                onBlur={partial(onFieldBlur, config)}
							isSecure={config.isSecure}
			                {...fieldModifierAttributes(config)}
			/>
		);
	}
