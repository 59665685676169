import "./SymbolSelectorAccordion.css"
import {Accordion, AccordionBody, AccordionHeader, AccordionItem, Button} from "reactstrap";
import {useEffect, useState} from "react";
import {ConfigurationOption} from "../../../components/input/configuration/ConfigurationOption";
import {Utils} from "../../../utils/Utils";
import {DisplayImage} from "../../../components/image/display/DisplayImage";

interface SymbolSelectorAccordionProps {
	options: ConfigurationOption[];
	onOptionClick: (option: ConfigurationOption) => void;
}

export const SymbolSelectorAccordion = ({options, onOptionClick}: SymbolSelectorAccordionProps) => {
	const [groupKeys, setGroupKeys] = useState<string[]>([])
	const [openAccordionItem, setOpenAccordionItem] = useState("");

	// Returns a sorted array of distinct group keys.
	const getOptionGroupKeys = () => {
		const groupKeys = Array.from(new Set(options.map(x => x.optionGroupKey ? x.optionGroupKey : "")));
		return groupKeys.sort((a, b) => b.localeCompare(a));
	};

	// Get accordion items once.
	useEffect(() => {
		setGroupKeys(getOptionGroupKeys());
	}, []);

	// Set default accordion item.
	useEffect(() => {
		setOpenAccordionItem(groupKeys[0]);
	}, [groupKeys]);

	const toggleAccordionItem = (groupKey: string) => {
		setOpenAccordionItem(openAccordionItem === groupKey ? "" : groupKey);
	};

	const handleOptionClick = (option: ConfigurationOption) => {
		onOptionClick(option);
	}

	const renderOptionButtonsByGroupKey = (groupKey: string) => {
		const groupOptions = options.filter(x => x.optionGroupKey === groupKey);
		return groupOptions
			.sort(Utils.sortBy("sortOrder"))
			.map((option, index) => {
				return (
					<Button key={index} onClick={() => handleOptionClick(option)} className="symbol-button">
						{option.imageId ? <DisplayImage imageId={option.imageId}/> : option.displayName}
					</Button>
				);
			});
	};

	const renderAccordionItems = () => {
		return groupKeys.map((groupKey) => {
			return (
				<AccordionItem key={groupKey}>
					<AccordionHeader targetId={groupKey}>
						<h5>{groupKey}</h5>
					</AccordionHeader>
					<AccordionBody accordionId={groupKey}>
						<span className="symbol-container">
							{renderOptionButtonsByGroupKey(groupKey)}
						</span>
					</AccordionBody>
				</AccordionItem>
			);
		})
	};

	return (
		<div>
			<Accordion open={openAccordionItem} toggle={toggleAccordionItem}>
				{renderAccordionItems()}
			</Accordion>
		</div>
	);
};
