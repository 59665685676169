import {ConfigurationEntryGroup} from "./ConfigurationEntryGroup";
import {Configuration} from "../components/input/configuration/Configuration";
import {ProductConfigurationGroup} from "./model";

interface ConfigurationEntryGroupResolverProps {
    readonly group: ProductConfigurationGroup;
    readonly onFieldBlur?: (config: Configuration) => void | Promise<void>;
}

const groupsWithHiddenLabels = [
    'BankingInformation'
];
const shouldHideLabels = (label: string) => groupsWithHiddenLabels.includes(label);

export function ConfigurationEntryGroupResolver({
                                                    group,
                                                    onFieldBlur = () => void 0,
                                                }: ConfigurationEntryGroupResolverProps) {
    if (group.configs.some(c => c.configurationDetail.accessoryProductId)) {
        return <></>
    }

    return (
        <ConfigurationEntryGroup group={group}
                                 showLabels={!shouldHideLabels(group.groupKey)}
                                 onFieldBlur={onFieldBlur}
        />
    );
}