import {useAppSelector} from "../../app/hooks";
import {getProductConfigurationPages} from "../ProductConfigurationSlice";
import {Col, Row} from "reactstrap";
import {ConfigurationPageReview} from "./ConfigurationPageReview";
import cn from "classnames";

interface ConfigurationReviewPanelProps {
    isReviewMode?: boolean;
}

export function ConfigurationReviewPanel({isReviewMode = false}: ConfigurationReviewPanelProps) {

    const pages = useAppSelector(getProductConfigurationPages);

    const panelCount = pages.filter(p => p.pagingKey !== 'FinalReview').length;

    const columnClassName = cn(
        'col-12',
        {'col-md-6': panelCount > 1}
    );

    return (
        <Row className="gy-2">
            {pages.filter(p => p.pagingKey !== 'FinalReview').map((p) =>
                <Col key={p.pagingKey} className={columnClassName}>
                    <ConfigurationPageReview page={p} isReviewMode={isReviewMode}/>
                </Col>
            )}
        </Row>
    )
}