import {ConfigurationEntryPageProps} from "../DefaultConfigurationEntryPage";
import {useAppSelector} from "../../app/hooks";
import {getSiteProductVariant} from "../ProductConfigurationSlice";
import {CheckReview} from "./CheckReview";
import {ItemSummary} from "./ItemSummary";
import {Col, Row} from "reactstrap";
import {ConfigurationReviewPanel} from "./ConfigurationReviewPanel";
import {SiteProductVariant} from "../../components/product/SiteProductVariant";

export const ConfigurationReviewPage = ({isReviewMode, siteId}: ConfigurationEntryPageProps) => {
    const siteProductVariant = useAppSelector(getSiteProductVariant);

    if (siteProductVariant) {
        return resolve(siteProductVariant, isReviewMode ?? false, siteId);
    }

    return (<></>);
}

function resolve(spv: SiteProductVariant, isReviewMode: boolean, siteId: number | undefined) {
    if (spv?.itemType?.supportsPreview) {
        return (
            <>
                <Row>
                    <Col>
                        <CheckReview sku={spv.sku} siteProductVariantId={spv.id} cartId={undefined} siteId={siteId}/>
                    </Col>
                    <Col md={4}>
                        <ItemSummary isReviewMode={isReviewMode}/>
                    </Col>
                </Row>
                <ConfigurationReviewPanel isReviewMode={isReviewMode}/>
            </>
        )
    } else {
        return (
            <>
                <Row>
                    <Col>
                        <div className="mw-100">
                            <ConfigurationReviewPanel isReviewMode={isReviewMode}/>
                        </div>
                    </Col>
                    <Col md={4}>
                        <ItemSummary isReviewMode={isReviewMode}/>
                    </Col>
                </Row>
            </>
        )
    }
}