import {ConfigurationRule, RuleTypes} from "../../components/input/configuration/rule/ConfigurationRule";
import {Configuration} from "../../components/input/configuration/Configuration";
import {z} from "zod";
import {curry} from "lodash/fp";
import {CrossFieldRuleValidator, RuleValidation, RuleValidator} from "./types";
import {FieldError} from "react-hook-form";
import {referencedConfig, ruleHasReferences} from "../model";

/**
 * Determine whether to revalidate a field
 *
 * @param fieldState
 */
export function shouldRevalidate({invalid, isDirty}: ShouldRevalidateParams) {
    return invalid && isDirty;
}

/**
 * RHF FieldState
 */
type ShouldRevalidateParams = {
    invalid: boolean;
    isDirty: boolean;
    isTouched: boolean;
    isValidating: boolean;
    error?: FieldError
};


/**
 * Create composable Rule Validator function for a rule type.
 *
 * Uses ZodSchema.superRefine()
 * @param type The rule
 * @param fieldValidation Validates value if there are no dependent values
 * @param crossFieldValidation Validates value if there are dependent values
 */
export function ruleValidationBuilder(type: RuleTypes,
                                      fieldValidation: RuleValidator,
                                      crossFieldValidation: CrossFieldRuleValidator = () => true)
    : RuleValidation {

    return ([rule, allConfigs, schema]) => {
        // not for this rule
        if (rule.configurationRuleType.name !== type) {
            return [rule, allConfigs, schema];
        }
        const config = allConfigs.find(c =>
            c.configurationDetail.id === rule.configurationDetailId);

        // there is no config
        if (!config) {
            return [rule, allConfigs, schema];
        }

        const refConfig = referencedConfig(rule, allConfigs);

        // If the rule has references and the referenced config does not 
        // exist then ignore the rule
        const refinedSchema = !ruleHasReferences(rule)
            ? schema.superRefine(fieldRefinement(config, rule, fieldValidation))
            : refConfig
                ? schema.superRefine(crossFieldRefinement(
                    config,
                    refConfig,
                    rule,
                    crossFieldValidation
                ))
                : schema

        return [
            rule,
            allConfigs,
            refinedSchema
        ]
    };
}

// Creates a zod superRefinement that will call the 
// validator with the value
const fieldRefinement = curry(({configurationKey, configurationDetail: {isRequired}}: Configuration,
                               rule: ConfigurationRule,
                               validator: RuleValidator,
                               data: any,
                               ctx: z.RefinementCtx) => {

    const value = getValue(rule, configurationKey, data);

    if (!isRequired && !value) {
        return;
    }

    if (!validator(rule, value)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [getPath(rule, configurationKey)],
            message: rule.ruleFailedMessage
        })
    }
});

// Creates a zod superRefinement that will call the validator
// with the rule and depenent values
const crossFieldRefinement = curry(({configurationKey: key, configurationDetail: {isRequired}}: Configuration,
                                    {configurationKey: refKey}: Configuration,
                                    rule: ConfigurationRule,
                                    refinement: CrossFieldRuleValidator,
                                    data: any,
                                    ctx: z.RefinementCtx) => {
    const value = getValue(rule, key, data);
    const referencedValue = getValue(rule, refKey, data);

    if (!isRequired && !value) {
        return;
    }
    if (!refinement(rule, value, referencedValue)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [getPath(rule, key)],
            message: rule.ruleFailedMessage
        })
    }
});

// Get a value using the rule or rule metaTag
// Migrated from Web/StorefrontApp/src/components/input/configuration/rule/ConfigurationRuleEvaluator.ts
// Some validation (address) require some cross field rules
// with dynamic values
// Also metaTags don't always line up with field names
const getValue = (rule: ConfigurationRule | null, key: string, data: any) => {
    const baseValue = data[key];
    if (rule === null) {
        return baseValue;
    }
    if (!rule.metaTag) {
        return data[key];
    }

    // Most Address fields are required so return empty strings
    // so Positive Match validation will fail them
    switch (rule.metaTag) {
        case 'streetAddress':
            return baseValue.street ?? '';
        case 'cityStateZip':
            return `${baseValue.city ?? ""}, ${baseValue.stateCode ?? ""} ${baseValue.zip ?? ""}`;
        default:
            return baseValue?.[metaTagToProperty(rule.metaTag)] ?? '';
    }
}

// Get a zod path for the rule
const getPath = (rule: ConfigurationRule, key: string) => {
    if (!rule.metaTag) {
        return key;
    }

    return `${key}.${metaTagToProperty(rule.metaTag)}`;

};

const metaTagToProperty = (metaTag: string) => {
    switch (metaTag) {
        case 'streetAddress':
            return 'street';
        case 'cityStateZip':
            return ''
        case 'phoneNumber':
            return 'phone';
        default:
            return metaTag;
    }
}
