import {PersonalDetailsPreview} from "./PersonalDetailsPreview";
import {useFormContext} from "react-hook-form";
import {ProductConfigurationGroup} from "./model";
import {PersonalizationPreview} from "./PersonalizationPreview";

interface ConfigurationGroupPreviewResolverProps {
    readonly group: ProductConfigurationGroup;
}

export function ConfigurationGroupPreviewResolver({group}: ConfigurationGroupPreviewResolverProps) {
    const {getValues} = useFormContext();

    return resolve(group, getValues);
}

function resolve(group: ProductConfigurationGroup, values: any) {
    switch (group.groupKey) {
        case "PersonalDetails":
            return (
                <PersonalDetailsPreview
                    nameOnAccount={values('NameOnAccount')}
                    additionalAccountName={values('AdditionalAccountName')}
                    thirdLineOfInformation={values('AddThirdLineOfInformation')}
                    street={values('CheckAddress.street')}
                    city={values('CheckAddress.city')}
                    state={values('CheckAddress.stateCode')}
                    zip={values('CheckAddress.zip')}
                    phoneNumber={values('CheckAddress.phoneNumber')}
                    font={values('ChooseFont')}
                />
            );
        case "Personalization":
            return (
                <PersonalizationPreview
                    line1={values('Line1')}
                    line2={values('Line2')}
                    line3={values('Line3')}
                    line4={values('Line4')}
                    line5={values('Line5')}
                    line6={values('Line6')}
                    justify={values('Justify')}
                    inkColor={values('InkColor')}
                    font={values('ChooseFont')}
                />
            );
        default:
            return (
                <></>
            );
    }
}