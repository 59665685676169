/**
 * This file contains functions for generating routes.
 * 
 * All exports should be created using the route() function
 */

import {assign, partial} from "lodash";
import {generatePath, PathParam} from "react-router-dom";
import {overArgs, mapValues, flow, omitBy, isNaN, toString} from "lodash/fp";


/**
 * Canonical Product Details route
 */
export const productDetailsRoute = route('/product/:paramProductLookup');

export const productConfigurationRoute = route('/productConfiguration/:siteProductVariantId/:quantityOptionId');

/**
 * Creates a function for route generation. The routing function accepts
 * an object containing all route parameters from the provided path
 * The returned function will have the provided path as the `path` property
 * for use in the router. The parameter types from react-router have been
 * augmented to support numbers as well as string
 * 
 * @example
 * 
 * const userProfileRoute = route('/user/:userId');
 * const myProfilePath = userProfileRoute({
 *   userId: 123  
 * });
 * 
 * <Route path={userProfileRoute.path} ...>
 *     
 * myProfilePath === '/user/123'
 * @param path
 * @return RouteFn
 */
function route<
    Path extends string
>(path: Path): RouteFn<Path> {
    // This creates the routing function and 
    // adds the path as a property of it.
    return assign(
        // Create a function of react-router's generatePath
        // with the path partially applied
        // Then when calling the function process the args to
        // what is acceptable by react-router
        overArgs(partial(generatePath, path), [
            flow(
                // Remove NaN properties
                omitBy(isNaN),
                // Convert all property values to a string
                mapValues(toString),
            )
        ]),
        // Sets path property of the function
        {path}
    )
}

type RouteParameters<Path extends string> = { 
    [key in PathParam<Path>]: string | number | null 
};
type RouteFn<Path extends string> =
    ((params: RouteParameters<Path>) => string)
    & { readonly path: Path};