import './SafetyAndSecurity.css';
import {useTranslation} from "react-i18next";
import {ReactComponent as Shield} from '../../assets/icons/shield.svg';
import {ReactComponent as MagnifyingGlass} from '../../assets/icons/magnifying-glass.svg';
import {ReactComponent as LightningBolt} from '../../assets/icons/lightning-bolt.svg';
import {ReactComponent as Lock} from '../../assets/icons/lock.svg';
import {ReactComponent as Printer} from '../../assets/icons/printer.svg';
import {ReactComponent as InkPen} from '../../assets/icons/ink-pen.svg';
import {Container} from 'reactstrap';
import {SiteCategory} from "../category/Category";
import {SiteProduct} from "../product/Product";
import {useGetParentSiteCategoriesQuery} from "../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";

interface SafetyAndSecurityProps {
	siteCategory?: SiteCategory;
	siteProduct?: SiteProduct;
}

export const SafetyAndSecurity = ({siteCategory, siteProduct}: SafetyAndSecurityProps) => {
	const {t} = useTranslation();
	
	let itemIconKeys = [
		'shield',
		'lightning-bolt',
		'magnifying-glass',
		'printer',
		'lock',
		'ink-pen'
	];

	const getItemIcon = (iconKey: string) => {
		switch (iconKey) {
			case 'shield':
				return <Shield className='security-item-icon'/>;
			case 'lightning-bolt':
				return <LightningBolt className='security-item-icon'/>;
			case 'magnifying-glass':
				return <MagnifyingGlass className='security-item-icon'/>;
			case 'printer':
				return <Printer className='security-item-icon'/>;
			case 'lock':
				return <Lock className='security-item-icon'/>
			case 'ink-pen':
				return <InkPen className='security-item-icon'/>;
			default:
				return <Shield className='security-item-icon'/>;
		}
	};
	
	// Only show "Heat-sensitive Ink" feature for personal checks (category or product).
	const { data: parentSiteCategories } = useGetParentSiteCategoriesQuery(siteCategory?.id ?? skipToken);
	
	const isPersonalCheck = parentSiteCategories?.some(x => x.id === 2)
		|| siteProduct?.types.some(x => x.name === "Personal Checking")
	
	if (!isPersonalCheck) {
		itemIconKeys = itemIconKeys.filter(x => x !== "ink-pen")
	}
	
	return (
		<div className='safety-and-security-background'>
			<Container className='safety-and-security-section d-flex flex-column'>
				<div className='safety-and-security-title-container d-flex flex-column'>
					<div className='safety-and-security-title-content d-flex flex-column'>
						<h2 className='safety-and-security-title d-flex flex-column'>
							{t('footer.safetyAndSecurity.title')}
						</h2>
					</div>
				</div>
				<div className='safety-and-security-items-container d-flex flex-column'>
					{itemIconKeys.map((itemIconKey, index) => {
						return (
							<div className="safety-and-security-item" key={`safety-and-security-item-${index}`}>
								<span>{getItemIcon(itemIconKey)}</span>

								<div className='safety-and-security-item-text-container d-flex flex-column'>
									<h4 className="safety-and-security-item-title">
										{t(`footer.safetyAndSecurity.item${index}Title`)}
									</h4>

									<p className="safety-and-security-item-content">
										{t(`footer.safetyAndSecurity.item${index}Content`)}
									</p>
								</div>
							</div>
						)
					})}
				</div>
			</Container>
		</div>
	);
};
