export const getFontClassName = (fontName: string) => {
    if (fontName === 'Calligraphy')
        return 'font-calligraphy';
    else if (fontName === 'CL212')
        return 'font-classic-condensed';
    else if (fontName === 'CP212')
        return 'font-title-plate';
    else if (fontName === 'EX212')
        return 'font-excalibur';
    else if (fontName === 'Flair')
        return 'font-flair';
    else if (fontName === 'Garamond')
        return 'font-garamond';
    else if (fontName === 'Helvetica')
        return 'font-block';
    else if (fontName === 'HW212')
        return 'font-handwriting';
    else if (fontName === 'KP212')
        return 'font-executive';
    else if (fontName === 'PR212')
        return 'font-classic-block';
    else if (fontName === 'RV212')
        return 'font-broadway';
};