import cn from "classnames";
import {getFontClassName} from "../utils/FontUtils";
import './PersonalDetailsPreview.css';

interface PersonalDetailsPreviewProps {
    nameOnAccount: string;
    additionalAccountName: string;
    thirdLineOfInformation: string;
    street: string;
    city: string;
    state: string;
    zip: string;
    phoneNumber: string;
    font: string;
}

export function PersonalDetailsPreview({
    nameOnAccount,
    additionalAccountName,
    thirdLineOfInformation,
    street,
    city,
    state,
    zip,
    phoneNumber,
    font
}: PersonalDetailsPreviewProps) {

    const previewClass = cn(
        'p-1',
        'd-flex',
        'flex-column',
        'justify-content-center',
        'align-items-center',
        'personal-details-preview',
        getFontClassName(font)
    );

    return (
        <div className={previewClass}>
            <div className="align-items-start">
                <div className="fw-bold fs-5">{nameOnAccount}</div>
                <div className="fw-bold fs-5">{additionalAccountName}</div>
                <div>{thirdLineOfInformation}</div>
                <div>{street}</div>
                <div>{city}{city && state ? ',' : ''} {state} {zip}</div>
                <div>{additionalAccountName && thirdLineOfInformation ? '' : phoneNumber}</div>
            </div>
        </div>
    );
}