import { Link, useNavigate } from "react-router-dom";
import './UserMenu.css';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectCurrentConsumerUser, clearSession} from "../login/AuthenticationSlice";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalFooter, ModalHeader, UncontrolledDropdown } from "reactstrap";
import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {useGetCurrentConsumerDetailsQuery} from "../../../app/apiSlice";
import {setCartId} from "../../cart/CartSlice";
import {ProfileTab} from "../profile/ProfileTab";
import {skipToken} from "@reduxjs/toolkit/query";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {usePersistentFormData} from "../../../productConfiguration/UsePersistProductConfigData";


export const UserMenu = () => {
  const consumer = useAppSelector(selectCurrentConsumerUser);
  const { data: currentConsumer } = useGetCurrentConsumerDetailsQuery(consumer ? undefined : skipToken);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const {clearPersistentFormData} = usePersistentFormData();

  const toggle = () => setModal(!modal);

  useEffect(() => {
    // If the user return is not the same as the user we thought was logged in
    // Or the current user apparently logged out
    if ((consumer && currentConsumer && consumer.id !== currentConsumer.id) ||
        (consumer && !currentConsumer)) {
      clearSession();
    }

    if (currentConsumer?.currentCartId)
      dispatch(setCartId(currentConsumer.currentCartId));
  }, [currentConsumer]);

  function renderLoginButton() {
    return (
      <Link className="signInButton" to={'/login'}>
        <FontAwesomeIcon icon={faUser} />
        <span className='user-signin-button user-action-text'>{t('userMenu.signIn')}</span>
      </Link>
    )
  }

  function signOutClicked() {
    // Close modal
    toggle();

    // Remove jwt and consumer from local storage
    dispatch(clearSession());
    
    // Clear any persisted product configuration data from storage
    clearPersistentFormData();
    
    // Reload window and navigate home
    navigate('/');
    window.location.reload();
  }

  function renderLogoutButton() {
    return (
        <>
          <span className="user-profile-email-display" style={{zIndex: 2000}}>
            <UncontrolledDropdown>
              <DropdownToggle className='user-profile-dropdown-button' color='none' caret>
                <FontAwesomeIcon icon={faUser} />
                <span className="user-profile-menu-text">
                  { consumer?.email }
                </span>
              </DropdownToggle>
              <DropdownMenu className='user-profile-dropdown-menu'>
                <Link to={`profile/${ProfileTab.ProfileDetails}`}>
                  <DropdownItem>
                    {t('userMenu.option1')}
                  </DropdownItem>
                </Link>
                <Link to={`profile/${ProfileTab.OrderHistory}`}>
                  <DropdownItem>{t('userMenu.option2')}</DropdownItem>
                </Link>
                <DropdownItem divider />
                <DropdownItem onClick={toggle}>{t('userMenu.option3')}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </span>
          <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader className="user-profile-sign-out-modal">{t('userMenu.modal.text')}</ModalHeader>
          <ModalFooter className="justify-content-center">
            <Button color="primary" onClick={signOutClicked}>
              {t('userMenu.modal.signOutButton')}
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              {t('userMenu.modal.cancelButton')}
            </Button>
          </ModalFooter>
        </Modal>
        </>
    );
  }

  let loginMenu = consumer
    ? renderLogoutButton()
    : renderLoginButton();

  return (
    <>
      {loginMenu}
    </>
  );

}