import {OrderGrid} from "./order/OrderGrid";
import {Button, Input} from "reactstrap";
import {useState} from "react";
import {OrderSearchCriteriaPanel} from "./order/OrderSearchCriteriaPanel";
import {Link} from "react-router-dom";
import './AdminHome.css';
import {useClaims} from "../../app/hooks";

export const AdminHome = () => {
    const {hasEditingRole} = useClaims();

    const [search, setSearch] = useState('');
    const [criteriaIsOpen, setCriteriaIsOpen] = useState(true);

    const ToolRow = () => (
        <div className='d-flex flex-row-reverse mb-3'>
            <Input
                className='w-50 mx-1 ms-auto'
                placeholder='Filter results by text...'
                defaultValue={search}
                onChange={e => setSearch(e.target.value)}/>
            {hasEditingRole &&
                <Link className='btn btn-primary mx-1'
                      to='order/new'>
                    Create New Order
                </Link>
            }
            <Button color='secondary' 
                    className='criteria-button btn-secondary'
                    outline={true}
                    onClick={() => setCriteriaIsOpen(old => !old)}>
                {criteriaIsOpen ? 'Close' : 'Open'} Search Criteria
            </Button>
        </div>
    );

    return (
        <div className='d-flex justify-content-between w-100 h-100'>
            <div className={'d-flex flex-column grid-criteria-container mx-2'}>
                <OrderSearchCriteriaPanel isOpen={criteriaIsOpen}/>
            </div>
            <div className='d-flex flex-column admin-home-right-side-content'>
                {ToolRow()}
                <OrderGrid search={search.toLowerCase()}/>
            </div>
        </div>
    );
}