import {FormInputField, FormInputProps} from "./FormInputField";
import {FieldValues} from "react-hook-form";
import {formatPhoneNumber} from "../../utils/Utils";
import React from "react";
import {isUsingAdminSite} from "../../utils/SiteHelper";

interface FormPhoneNumberInputProps<
    Schema extends FieldValues
> extends Omit<FormInputProps<Schema>, 'placeholder'> {

}

export function FormPhoneNumberInput<
    Schema extends FieldValues
>({
      parseValue = value => value,
      ...props
  }: FormPhoneNumberInputProps<Schema>) {

    const isAdminSite = isUsingAdminSite();

    return (
        <FormInputField {...props}
                        type="tel"
                        placeholder='(   ) ___-____'
                        parseValue={value => parseValue(formatPhoneNumber(value))}
                        autoComplete={isAdminSite ? "autocomplete_off" : "tel-national"}
        />
    );
}