import {BankInformation} from "../../components/bank/information/BankInformation";
import {ConfigurationEntryPageProps, DefaultConfigurationEntryPage} from "../DefaultConfigurationEntryPage";
import {useAppSelector} from "../../app/hooks";
import {getCurrentPage, getSiteProductVariant} from "../ProductConfigurationSlice";
import {useValidateBankingUserInputMutation} from "../../app/apiSlice";
import {useFormContext, useWatch} from "react-hook-form";
import {
    BankConfigurationKeys
} from "../../components/input/configuration/Configuration";
import {OnFieldBlurHandler} from "../validation/types";

export function BankingInformationEntryPage({page}: ConfigurationEntryPageProps) {
    
    const currentPage = useAppSelector(getCurrentPage);
    const siteProductVariant = useAppSelector(getSiteProductVariant);
    const [validateBankingUserInput] = useValidateBankingUserInputMutation();

    const {setValue} = useFormContext();
    const [routingNumber, depositRoutingNumber, accountNumber] = useWatch({
        name: [
            BankConfigurationKeys.RoutingNumber,
            BankConfigurationKeys.DepositRoutingNumber,
            BankConfigurationKeys.AccountNumber
        ]
    });

    const getConfigValue = (configurationKey: string) => {
        switch (configurationKey) {
            case BankConfigurationKeys.RoutingNumber:
                return routingNumber;
            case BankConfigurationKeys.DepositRoutingNumber:
                return depositRoutingNumber;
            case BankConfigurationKeys.AccountNumber:
                return accountNumber;
        }
        return '';
    }
    
    const onFieldBlur: OnFieldBlurHandler = async ({
                                                       configurationKey,
                                                       id: configurationId,
                                                   }) => {

        if (!siteProductVariant) {
            console.warn('BankingInformationEntryPage:OnFieldBlur - no site product variant set');
            return;
        }

        if (!validateOnBlur.includes(configurationKey)) {
            return;
        }

        try {
            const res = await validateBankingUserInput({
                routingNumber,
                depositRoutingNumber,
                accountNumber,
                configurationId,
                productVariantId: siteProductVariant.id,
                stringValue: getConfigValue(configurationKey)
            }).unwrap();

            setValue(BankConfigurationKeys.BankName, res.bankName);
            setValue(BankConfigurationKeys.BankAddress, res.printedBankAddress);
        }
        catch (e) {
            console.log('validate bank error', e);
            setValue(BankConfigurationKeys.BankName, '');
            setValue(BankConfigurationKeys.BankAddress, '');
        }
    }

    return (
        <>
            <h2>{currentPage.name}</h2>
            <BankInformation/>
            <DefaultConfigurationEntryPage page={page}
                                           onFieldBlur={onFieldBlur}/>
        </>
    );
}

const validateOnBlur: readonly string[] = [
    BankConfigurationKeys.RoutingNumber,
    BankConfigurationKeys.DepositRoutingNumber,
    BankConfigurationKeys.AccountNumber,
]