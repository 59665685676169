import {useAppSelector} from "../../app/hooks";
import {getQuantityOption, getSiteProductVariant} from "../ProductConfigurationSlice";
import {getSelectedComponentOptions, optionForValue} from "../model";
import {useGetComponentsForVariantQuery} from "../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {Component} from "../../components/input/component/Component";
import {CurrencyFormatter} from "../../utils/CurrencyFormatter";
import {LoadingSpinner} from "../../components/input/utils/LoadingSpinner";
import {useFormContext} from "react-hook-form";
import {Configuration} from "../../components/input/configuration/Configuration";
import {Button, Card, CardBody, CardHeader, CardTitle, ListGroup, ListGroupItem} from "reactstrap";
import {partial} from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {ConfigPriceChange, useConfigsWithPriceChange} from "../UseConfigsWithPriceChange";
import {AddToCartButton} from "../inputs/AddToCartButton";

const DEFAULT_COMPONENTS: Component[] = [];

interface ItemSummaryProps {
	isReviewMode: boolean;
}

export const ItemSummary = ({isReviewMode}: ItemSummaryProps) => {
	const siteProductVariant = useAppSelector(getSiteProductVariant);
	const quantityOption = useAppSelector(getQuantityOption);

	const {
		data: components = DEFAULT_COMPONENTS
	} = useGetComponentsForVariantQuery(siteProductVariant?.id ?? skipToken);

	const {changes, subTotal} = useConfigsWithPriceChange();

	if (!siteProductVariant || !quantityOption) {
		return <LoadingSpinner/>;
	}

	return (
		<Card>
			<CardHeader className="fs-5 bolded-text text-muted">
				Item Summary
			</CardHeader>
			<CardBody>
				<CardTitle>
					{siteProductVariant.name}
				</CardTitle>
				<div className="d-flex border-bottom pb-2">
                    <span>
                        {quantityOption.description}
                    </span>
					-
					<span className="flex-grow-1">
                        {getSelectedComponentOptions(components, siteProductVariant)
	                        .map(componentOption => (
		                        <span key={componentOption.value}>
                                {componentOption.description}
                            </span>
	                        ))}
                    </span>

					<span className="flex-shrink-1">
                        {CurrencyFormatter.format(quantityOption.price)}
                    </span>
				</div>

				<ItemSummaryAddonList changes={changes} isReviewMode={isReviewMode}/>

				<div className="d-flex bolded-text">
                    <span className="flex-grow-1">
                        SubTotal
                    </span>
					<span className="flex-shrink-1">
                        {CurrencyFormatter.format(subTotal)}
                    </span>
				</div>
				{!isReviewMode &&
					<div>
						<AddToCartButton className="w-100 mt-4"/>
					</div>
				}
			</CardBody>
		</Card>
	)
}

interface VariantComponentListProps {
	readonly changes: readonly ConfigPriceChange[];
	readonly isReviewMode: boolean;
}

const ItemSummaryAddonList = ({changes, isReviewMode}: VariantComponentListProps) => {

	const {getValues, resetField} = useFormContext();

	const onRemoveClick = ({configurationKey, configurationDetail}: Configuration) => {
		if (configurationKey.startsWith('ChooseCheckFraudProtection')) {
			const defaultOption = configurationDetail?.options?.find((x) => x.isDefault);
			resetField(configurationKey, {defaultValue: defaultOption?.value});
		} else {
			resetField(configurationKey);
		}
	}

	return (
		<>
			<ListGroup flush className="border-bottom mb-2">
				{changes.map(([c, change]) => (
					<ListGroupItem key={c.configurationKey} className="d-flex flex-wrap flex-row px-0 border-0">
                        <span className="flex-grow-1 col-8">
                            {getLabel(c, getValues(c.configurationKey))}
                        </span>
						<span className="flex-shrink-1 col-4 justify-content-end d-flex">
                            {CurrencyFormatter.format(change)}
                        </span>
						{!isReviewMode &&
							<Button size="sm"
									className="bg-transparent border-0 text-danger px-0"
									onClick={partial(onRemoveClick, c)}>
								<FontAwesomeIcon icon={faTrashAlt} size="sm"/>
								Remove
							</Button>
						}
					</ListGroupItem>
				))}
			</ListGroup>
		</>
	);
}

function getLabel(c: Configuration, value: any) {
	return c.configurationDetail.label
		|| c.configurationDetail.actionLabel
		|| optionForValue(c, value)?.displayName
		|| '';
}
