import {ProductConfigurationInputProps} from "../inputs/ProductConfigurationInputProps";
import {fieldModifierAttributes} from "../FieldModifiers";
import classnames from "classnames";
import {useWatch} from "react-hook-form";

export function BankName({config}: ProductConfigurationInputProps) {

    const bankName = useWatch({
        name: config.configurationKey
    });
    
    const {className: fmClassname} = fieldModifierAttributes(config);
    const className = classnames(
        'text-success',
        fmClassname,
        {
            'd-none': !bankName
        }
    )
    
    return (
        <div className={className}>
            <p className="mb-2">
                Bank Found
            </p>
            <p>
                {bankName}
            </p>
        </div>
    );
}