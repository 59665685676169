import {Card, CardBody, CardTitle} from "reactstrap";
import {ConfigurationGroupPreviewResolver} from "./ConfigurationGroupPreviewResolver";
import {MediaQueryTypes, useMediaQuery} from "../app/hooks";
import cn from "classnames";
import {ProductConfigurationGroup} from "./model";
import {InfoLabel} from "./inputs/InfoLabel";

interface ConfigurationGroupPreviewProps {
    readonly group: ProductConfigurationGroup;
}

export function ConfigurationGroupPreview({group}: ConfigurationGroupPreviewProps) {
    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

    const containerClass = cn(
        'my-0',
        {
            'm-1': isMobile,
            'm-5': !isMobile,
        },
        'overflow-hidden',
        'text-nowrap',
    );

    const cardClass = cn(
        'border',
        'border-dark',
        'border-1',
        'p-2',
        'bg-secondary',
        'bg-opacity-10',
    );

    return (
        <div className={containerClass}>
            <Card className={cardClass}>
                <CardTitle className="text-secondary position-absolute">Preview:</CardTitle>
                <CardBody>
                    <div className="justify-content-center">
                        <ConfigurationGroupPreviewResolver group={group} />
                    </div>
                </CardBody>
            </Card>
            <div className="text-wrap">
                <InfoLabel>The Preview above is approximately what your product will look like.</InfoLabel>
            </div>
        </div>
    );
}