import {ChangeEvent} from "react";
import {get as _get} from 'lodash';
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {Configuration} from "../components/input/configuration/Configuration";
import {FULL_STORY_SECURE_ELEMENT_CLASS, isProductInputSecure, ProductInput} from "../components/input/ProductInput";
import {InputClassification, ProductInputValue} from "../components/input/ProductInputValue";
import {SiteProductVariantQuantityOption} from "../components/product/SiteProductVariantQuantityOption";
import {ConfigurationDetail} from "../components/input/configuration/ConfigurationDetail";
import {Address} from "../components/input/address/Address";
import {acquireAccessToken} from "../index";
import {
    MICR_ACCOUNT_NUMBER_CHARACTER, MICR_ONUS_CHARACTER,
    MICR_OPTIONAL_ACCOUNT_NUMBER_CHARACTER, MICR_ROUTING_NUMBER_BOUNDARY_CHARACTER
} from "../components/admin/micr/MicrFormat";
import {TFunction} from "i18next";

export class Utils {
    /**
     * @param valueAccessor function that finds property of `T` to sort by, or string property of `T`
     * @param direction
     * @return Sort function for `T[]`
     */
    static sortBy = <T>(valueAccessor: ((source: T) => any) | keyof T, direction: 'asc' | 'desc' = 'asc') => (
        (a: T, b: T) => {
            const directionModifier = direction === 'desc' ? -1 : 1;
            const getValue = typeof valueAccessor === 'function' ? valueAccessor : (s: T) => _get(s, valueAccessor);
            let valA = getValue(a) ?? '';
            let valB = getValue(b) ?? '';
            // make string sorting case-insensitive
            valA = typeof valA === 'string' ? valA.toLowerCase() : valA;
            valB = typeof valB === 'string' ? valB.toLowerCase() : valB;
            let sort = 0;
            if (valA < valB) sort = -1;
            else if (valA > valB) sort = 1;
            return sort * directionModifier;
        }
    )

    /**
     * Gets a change event handler for HTMLInputElements that updates state through the
     * provided stateSetter function. It uses the `type` and `name` attributes of the
     * HTMLInputElement, so make sure those attributes are set correctly. The name should
     * correspond to a property of the state.
     * @param stateSetter function that sets the state that this function will work on.
     * @return function that will function as an onChange event handler for HTMLInputElements
     */
    static handleChanges = (stateSetter: any) => (e: ChangeEvent<HTMLInputElement>) => {
        let value: string | number | undefined = e.target.value;

        if (value === '')
            value = undefined;
        else if (e.target.type === 'number' || e.target.type === 'select-one')
            value = Number.parseFloat(value);

        // reset value to what was provided if it comes to NaN for some reason
        // one case is for the state selector which uses string state codes instead of ids.
        if (Number.isNaN(value)) value = e.target.value;

        stateSetter((old: any) => ({
            ...old,
            [e.target.name]: value,
        }))
    }

    static arrayToObject<TType, RType, Key extends PropertyKey>(src: TType[], keyValue: (value: TType, index: number) => [Key, RType]): Record<Key, RType> {
        return src.reduce((map, current, currentIndex) => {
            const keyPair = keyValue(current, currentIndex);
            map[keyPair[0]] = keyPair[1];
            return map;
        }, {} as Record<Key, RType>);
    }

    static collectToArray<TType, RType, Key extends PropertyKey>(src: TType[], keyValue: (value: TType, index: number) => [Key, RType]): Record<Key, RType[]> {
        return src.reduce((map, current, currentIndex) => {
            const keyPair = keyValue(current, currentIndex);
            const arr = map[keyPair[0]] ?? (map[keyPair[0]] = []);
            arr.push(keyPair[1]);
            return map;
        }, {} as Record<Key, RType[]>)
    }

    static isValidNumber(o: any): o is number {
        return typeof o === 'number' && !isNaN(o);
    }

    static isTrue(o: any): o is string | undefined | null {
        return o !== undefined && o !== null && o.length > 0 && o !== "false";
    }

    static mapCamelCaseToCapitalizedString(input?: string): string {
        if (!input)
            return "";

        // Insert space before each capital letter preceded by a lowercase letter
        const capitalized = input.replace(/([a-z])([A-Z])/g, '$1 $2');

        // Capitalize each word
        const words = capitalized.split(' ');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

        // Join the words with spaces
        return capitalizedWords.join(' ');
    }
}

export type SliceState = 'idle' | 'loading' | 'failed' | 'succeeded';

export enum BrowserStorageType {
    LocalStorage,
    SessionStorage
}

export const fetchStorageValue = (key: string, type: BrowserStorageType) => {
    try {
        const serialState = type === BrowserStorageType.LocalStorage ? localStorage.getItem(key) : sessionStorage.getItem(key);
        if (serialState === null) {
            return undefined;
        }
        return JSON.parse(serialState);
    } catch (err) {
        console.error(err);
        return undefined;
    }
};

export const persistInStorage = (key: string, value: any, type: BrowserStorageType) => {
    try {
        const serialState = JSON.stringify(value);
        if (type === BrowserStorageType.LocalStorage)
            localStorage.setItem(key, serialState);
        else
            sessionStorage.setItem(key, serialState);
    } catch (err) {
        console.error(err);
    }
};

export const removeFromStorage = (key: string) => {
    try {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
    } catch (err) {
        console.error(err);
    }
}

export const clearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
}

export const getConfigurationIsSecureClass = (productInput?: ProductInput) => {
    return isProductInputSecure(productInput) ? FULL_STORY_SECURE_ELEMENT_CLASS : "";
}

export const formatPhoneNumber = (phoneNumber?: string) => {
    if (!phoneNumber) return phoneNumber;

    // remove all non-digit characters
    phoneNumber = phoneNumber.replace(/\D/ig, "");

    if (phoneNumber.length > 3) {
        phoneNumber = phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, phoneNumber.length);
    }

    if (phoneNumber.length > 7) {
        phoneNumber = phoneNumber.substring(0, 7) + '-' + phoneNumber.substring(7, phoneNumber.length);
    }

    // Remove extra characters
    // This isn't handled by input max length because the max length is uncapped so that copy-pasting irregular text works
    phoneNumber = phoneNumber.substring(0, 12);

    return phoneNumber;
}

// Max length to 14 to support pasting phone number in with parenthesis. Could be high to support any copy paste content, then just take first 10 numbers
export const phoneNumberInputMaxLength = 100;

export const formatZipCode = (zipInput?: string) => {
    if (!zipInput) return zipInput;

    // remove all non-digit characters
    zipInput = zipInput.replace(/\D/ig, "");

    // add dash for longers zips, e.g. 35080-0123
    if (zipInput.length > 5)
        zipInput = zipInput.substring(0, 5) + zipInput.substring(5, zipInput.length);

    return zipInput;
}

export const formatNumericString = (text?: string) => {
    return text?.replace(/\D/ig, "");
}

export const createArrayOfNumbersUsingMinAndMax = (min: number, max: number): number[] => {
    const numbers = [];
    for (let i = min; i <= max; i++) {
        numbers.push(i);
    }
    return numbers;
}

export const replaceStringValueAtIndex = (stringToUpdate: string, index: number, valueToSet: string) => {
    return stringToUpdate.substring(0, index) + valueToSet + stringToUpdate.substring(index + 1, stringToUpdate.length + 1);
}

export const notImplemented = (alert: boolean = false) => {
    console.warn('Unimplemented feature:', new Error().stack);
    alert && window.alert('Not Implemented Yet 🔨')
};

/**
 * Takes in a RTK Query error from the server and breaks off the stack trace and rest of the information to
 * return only the error/exception message.
 * @param error Error from RTK-Query
 */
export const getErrorMessage = (error?: FetchBaseQueryError | SerializedError): string | undefined => {
    if (!error || !('data' in error)) return undefined;
    if (error.data && typeof error.data === 'string') return (error.data as string).split(' at ')[0].replace("System.Exception: ", "");
    if (error.data && typeof error.data === 'object' && ('Message' in error.data) && typeof error.data.Message === 'string') return (error.data.Message as string);
    return undefined;
}

export const getPrettyAddress = (address?: Address) => {
    if (!address?.street || !address.city || !address.stateCode || !address.zip)
        return "No address value";
    return `${address.street}, ${address.city}, ${address.stateCode} ${address.zip}`;
}

export const isAddressEmpty = (address?: Address) => {
    return (!address?.street && !address?.city && !address?.stateCode && !address?.zip);
}

export const replaceStringCharacterGroupWithValue = (charToReplace: string, sourceString: string, stringToInsert: string) => {
    const lastIndex = sourceString.lastIndexOf('R');
    const firstIndex = sourceString.indexOf('R');
    const indexRange = lastIndex - firstIndex;

    if (indexRange > stringToInsert.length)
        stringToInsert = stringToInsert.padEnd(indexRange + stringToInsert.length);

    return sourceString.substring(0, firstIndex) + stringToInsert + sourceString.substring(lastIndex + 1, sourceString.length);

}

export function formatDate(date: string) {
    const convertToDate = new Date(date + 'Z');

    const dateString = convertToDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric"
    });

    return dateString;
}

export const calculatePrice = (configurations: Configuration[], productInputs: ProductInputValue[], quantityOption: SiteProductVariantQuantityOption) => {
    const configurationMap = Utils.arrayToObject(
        configurations.filter(c => Array.isArray(c.configurationDetail.options)),
        c => [c.id, c]
    );

    const value = productInputs
        .filter(
            c => c.configurationId in configurationMap
                && c.inputClassification === InputClassification.Configuration)
        .map(c => {
            const price = getPrice(configurationMap[c.configurationId].configurationDetail, c);
            return price;
        })
        .reduce((pVal, cVal) => pVal + cVal, 0);

    return value + quantityOption.price;

    function getPrice(configDetails: ConfigurationDetail,
                      inputVal: ProductInputValue
    ) {
        const foundOpt = !Utils.isValidNumber(inputVal.selectedProductInputOptionId)
            ? undefined
            : (configDetails.options
                    ?.find(opt => opt.id === inputVal.selectedProductInputOptionId)
                ?? findConfigurationOptionFromChildId(
                    configDetails,
                    inputVal.selectedProductInputOptionId));

        if (!foundOpt
            && (Utils.isValidNumber(inputVal.selectedProductInputOptionId)
                || !inputVal.value)
        )
            return 0;

        return foundOpt?.addOn?.price
            ?? foundOpt?.priceModification
            ?? (Utils.isTrue(inputVal.value)
                ? configDetails.priceModification ?? 0
                : 0);
    }

    function findConfigurationOptionFromChildId(configDetail: ConfigurationDetail, childConfigId: number) {
        return (configDetail.options ?? []).find(configOption => {
            return !!(configOption.childConfigurationOptions ?? []).find(childConfig => {
                return childConfig.id === childConfigId;
            });
        })
    }
}

export const Regexes = {
    email: RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
    password: RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[_!@#$%^&*-])(?=.*[a-z]).{8,100}$/),
    zipCode: RegExp(/^\d{5}(?:[-\s]\d{4})?$/i),
    ccWithFormatting: RegExp(/^[\d ]{14,20}$/i),
    number: RegExp(/^\d+$/i),
    aba: RegExp(/^\d{9}$/),
    accountNumber: RegExp(/^\d{1,15}$/),
    fractionalNumber: RegExp(/^\d{1,2}-\d{1,4}\/\d{1,4}$/i),
    phoneNumber: RegExp(/^\d{3}-\d{3}-\d{4}$/),
    suffix: RegExp(/^((sr|senior|jr|junior|cm|cd)(xc|xl|l?x{0,3})(ix|iv|v?i{0,3})\.?)$/),

    // micr
    micrAccountNumberChar: RegExp(MICR_ACCOUNT_NUMBER_CHARACTER, 'g'),
    micrOptionalAccountNumberChar: RegExp(MICR_OPTIONAL_ACCOUNT_NUMBER_CHARACTER, 'g'),
    micrOnusChar: RegExp(MICR_ONUS_CHARACTER, 'g'),
    micrRoutingBoundaryChar: RegExp(MICR_ROUTING_NUMBER_BOUNDARY_CHARACTER, 'g'),
} as const;

export const validateExpirationDateString = (dateYearString?: string) => {
    if (!dateYearString)
        return "Expiration date is required."
    const currentDate = new Date();
    const monthYearParts = dateYearString.match(/^(\d{1,2})(\d{2})$/);

    if (!monthYearParts) {
        return 'Invalid month and year format';
    }

    const month = Number(monthYearParts[1]) - 1; // Months are zero-based (0-11)
    const year = Number(`20${monthYearParts[2]}`);
    const targetDate = new Date(year, month);

    return targetDate < currentDate ? "Please enter an expiration date in the future" : undefined;
}

export const validateCVCString = (cvcString?: string) => {
    if (!cvcString)
        return "CVC is required."

    return cvcString.length > 4 || cvcString.length < 3 ? "Please enter a cvv between 3 and 4 characters long" : undefined;
}

export const validateCCFullNameString = (ccFullName?: string) => {
    // Return undefined here because individual first name and last name errors will handle this
    if (!ccFullName)
        return undefined

    return ccFullName.length > 22 ? "Please enter a full name less than 23 characters" : undefined;
}

export const downloadWithAuth = async (url: string, fileName: string, method: string = 'GET') => {
    const token = await acquireAccessToken();
    const res = await fetch(url, {
        method,
        headers: {
            authorization: `Bearer ${token}`
        }
    });

    // return if no content
    if (res.status === 204)
        return false;

    const blob = await res.blob();
    const objUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = objUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(objUrl);
    return true;
}

export type RequiredKey<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export const readFileToBase64 = async (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        if (!reader.result) {
            reject('No result found.');
            return;
        }
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
    }
    reader.onerror = () => reject(reader.error);
});

export const getEnumValueFromName = <T extends Record<string, string>>(enumType: T, name?: string) => {
    if (!name) return undefined;
    // Loop through the enum keys and find a matching enum member
    for (const key of Object.keys(enumType)) {
        if (enumType[key] === name) {
            return enumType[key];
        }
    }
    return undefined; // Return undefined if no matching enum member found
}

export const getTranslationValue = (t: any, key: string) => {
    return t(key) && t(key) != key ? t(key) : null
}

export const doesTranslationValueExist = (t: any, key: string) => {
    return t(key) && t(key) != key
}

export const invalidHostNames = ['ecommerce.mainstreetinc.com']

export const isValidSite = (hostname: string) => {
    return !invalidHostNames.includes(hostname);
}

export const isPrerender = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes("prerender");
};

/**
 * Attempt to parse a value as json. Returns the original
 * value if it can not be parsed
 * @param value
 */
export function tryParseJson<
    T extends string,
    R extends object
>(value: T): T | R {
    try {
        return JSON.parse(value);
    }
    catch {
        return value;
    }
}