import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import {ConfigurationGroupReviewResolver} from "./ConfigurationGroupReviewResolver";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getProductConfigurationPages, goToPage} from "../ProductConfigurationSlice";
import {pageHasKey, ProductConfigurationPage} from "../model";
import './ConfigurationPageReview.css';

interface ConfigurationPageReviewProps {
    page: ProductConfigurationPage;
    isReviewMode: boolean;
}

export function ConfigurationPageReview({page, isReviewMode}: ConfigurationPageReviewProps) {
    const dispatch = useAppDispatch();

    const pages = useAppSelector(getProductConfigurationPages);

    function editClicked(page: ProductConfigurationPage) {
        const pageIndex = pages?.findIndex(p => pageHasKey(p, page.pagingKey));
        dispatch(goToPage(pageIndex));
    }

    return (
        <Card className="review-card">
            <CardTitle className="container">
                <Row>
                    <Col className="col-auto me-auto h5 text-secondary pt-2">
                        {page.name}
                    </Col>
                    <Col className="col-auto">
                        {!isReviewMode &&
                            <Button onClick={() => editClicked(page)} className="bg-transparent border-0 text-primary">
                                <FontAwesomeIcon flip="horizontal" icon={faPencil}/>
                                Edit
                            </Button>
                        }
                    </Col>
                </Row>
            </CardTitle>
            <CardBody>
                {page.groups.map(g =>
                    <ConfigurationGroupReviewResolver group={g} key={g.groupKey}/>
                )}
            </CardBody>
        </Card>
    )
}