import {ProductConfigurationInputProps} from "../ProductConfigurationInputProps";
import {FormInputField} from "../../../components/forms/FormInputField";
import {MediaQueryTypes, useAppSelector, useMediaQuery} from "../../../app/hooks";
import {useGetPrintingSoftwareQuery} from "../../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {selectCurrentConsumerUser} from "../../../components/user/login/AuthenticationSlice";
import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import cn from "classnames";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {shouldRevalidate} from "../../validation/utils";

export const PrintingSoftwareSelector = ({config}: ProductConfigurationInputProps) => {
	const OTHER_VALUE = "Other";
	const consumerId = useAppSelector(selectCurrentConsumerUser)?.id;
	const [showOtherTextbox, setShowOtherTextbox] = useState(false);
	const {getValues, setValue, getFieldState, trigger} = useFormContext();
	//TODO: Tech debt; Avoid hard-coded database keys.
	const numSignatureLines = getValues("SignatureLines");
	const {data: printingSoftwareList} =
		useGetPrintingSoftwareQuery(numSignatureLines ? {consumerId, numSignatureLines} : skipToken);
	const fieldState = getFieldState(config.configurationKey);
	const fieldValue = getValues(config.configurationKey);
	const isFieldValueInList = printingSoftwareList?.some(ps => ps.displayName === fieldValue) ?? false;
	const hasFieldValue = !!fieldValue;
	//TODO: Tech debt; Handle viewport changes in CSS.
	const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);
	const containerClass = cn(isMobile ? "" : "d-flex d-flex-row");
	const selectorClass = cn(isMobile ? "" : "col-5");

	const options = () => {
		const optionDefault = {text: "Select Your Printing Software", value: ""};
		const optionOther = {text: "Other...", value: OTHER_VALUE};

		// If the list fails, still allow the user to enter a text value.
		if (!printingSoftwareList) {
			return [optionDefault, optionOther];
		}

		return ([
			optionDefault,
			...printingSoftwareList?.map(o => ({
				text: o.displayName ?? "(no display name)",
				value: o.displayName ?? "(no display name)"
			})),
			optionOther
		]);
	};

	// Show or hide the text input via CSS.
	const textInputClass = cn({
		//TODO: Tech debt; Handle viewport changes in CSS.
		"col-7 px-5": !isMobile,
		"d-none": !showOtherTextbox,
	});

	const onSelectChange = async (value: string) => {
		if (value === OTHER_VALUE) {
			setValue(config.configurationKey, "");
			setShowOtherTextbox(true);
		} else {
			setValue(config.configurationKey, value, {shouldDirty: true});
			setShowOtherTextbox(false);
		}
		revalidate(config.configurationKey);
	};

	const revalidate = (field: string) => {
		if (shouldRevalidate(getFieldState(field))) {
			trigger(field);
		}
	}

	// Determine if text input should show on page entry.
	useEffect(() => {
		setShowOtherTextbox(!isFieldValueInList && hasFieldValue);
	}, [printingSoftwareList]);

	// Note: The FormInputField is for the actual configuration value.
	// The select input is not registered with the form.
	return (
		<div className={containerClass}>
			<div className={selectorClass}>
				<Select label={config.label}
				        options={options()}
				        onSelect={onSelectChange}
				        invalid={fieldState.invalid && !showOtherTextbox}
				        errorMsg={fieldState.error?.message}
				        defaultValue={hasFieldValue && isFieldValueInList
					        ? fieldValue
					        : hasFieldValue ? OTHER_VALUE : undefined}
				/>
			</div>
			<div className={textInputClass}>
				<FormInputField name={config.configurationKey}
				                label="Printing Software Name"
				                type={showOtherTextbox ? "text" : "hidden"}
				/>
				<div className="text-black-50">
					<FontAwesomeIcon icon={faCircleInfo} className="me-2"/>
					Please email a copy of your check to <></>
					<a target="_blank" href="mailto:customerservice@checkworks.com">
						customerservice@checkworks.com
					</a>.
					Reference your order number and the name on the order.
				</div>
			</div>
		</div>
	);
};

interface SelectProps {
	readonly defaultValue: any;
	readonly invalid: boolean;
	readonly errorMsg?: string;
	readonly label: string;
	readonly options: { text: string; value: string }[];
	readonly onSelect: (value: string) => void;
}

const Select = ({
	                options,
	                label,
	                invalid,
	                errorMsg,
	                defaultValue,
	                onSelect: _onSelect,
                }: SelectProps) => {

	const onSelect = (e: React.SyntheticEvent<HTMLInputElement>) => {
		_onSelect(e.currentTarget.value)
	};

	return (
		<FormGroup>
			<Label>{label}</Label>
			<Input type="select"
			       onChange={onSelect}
			       invalid={invalid}
			>
				{options.map((o, i) => (
					<option value={o.value} key={i} selected={o.value === defaultValue}>
						{o.text}
					</option>
				))}
			</Input>
			{invalid && <FormFeedback>{errorMsg}</FormFeedback>}
		</FormGroup>
	);
};
