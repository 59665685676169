import {useTranslation} from "react-i18next";
import {DisplayImage} from "../../image/display/DisplayImage";
import {InfoLabel} from "../../../productConfiguration/inputs/InfoLabel";


export const BankInformation = () => {
    const { t } = useTranslation();

    return (
        <span className="d-flex flex-column mb-1 text-center">
            <DisplayImage imageName="BankLineDescription"></DisplayImage>
            <InfoLabel>{t('bank.information.bottomBannerText')}</InfoLabel>
        </span>
    )
}
