import cn from 'classnames';
import {ConfigurationEntryGroupResolver} from "./ConfigurationEntryGroupResolver";
import {Configuration} from "../components/input/configuration/Configuration";
import {ProductConfigurationPage} from "./model";

export interface ConfigurationEntryPageProps {
    readonly page: ProductConfigurationPage;
    readonly className?: string;
    readonly onFieldBlur?: (config: Configuration) => void | Promise<void>;
    isReviewMode?: boolean;
    siteId?: number;
}

export function DefaultConfigurationEntryPage({
                                                  page: {groups},
                                                  className: _className = '',
                                                  onFieldBlur = () => void 0,
                                                  isReviewMode = false,
                                              }: ConfigurationEntryPageProps) {

    const className = cn(
        'configuration-page',
        _className
    );

    return (
        <div className={className}>
            {!isReviewMode && groups?.map((g, i) => (
                <section key={`group-${i}`}>
                    <ConfigurationEntryGroupResolver group={g}
                                                     onFieldBlur={onFieldBlur}
                    />
                </section>
            ))}
        </div>
    );
}