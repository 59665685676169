import cn from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {PropsWithChildren} from "react";

export const InfoLabel = ({ children }: PropsWithChildren) => {
    const infoClass = cn(
        'p-1',
        'mt-0',
        'text-black',
        'text-opacity-50',
    );
    
    return (
        <div className={infoClass}>
            <FontAwesomeIcon icon={faCircleInfo}/>{' '}
            {children}
        </div>
    )
}