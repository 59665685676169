import {getConfigsWithPriceChange, getQuantityOption} from "./ProductConfigurationSlice";
import {useEffect, useState} from "react";
import {useWatch} from "react-hook-form";
import {configurationPriceModifications} from "./model";
import {reduce} from "lodash/fp";
import {useAppSelector} from "../app/hooks";
import {Configuration} from "../components/input/configuration/Configuration";

export type ConfigPriceChange = [conig: Configuration, change: number];

/**
 * Maintain a list of form values that include price increases as
 * well as the subtotal of the selected quantity an configurations
 *
 * @return {changes: ConfigPriceChange, subTotal: number}
 */
export const useConfigsWithPriceChange = () => {

	const configs = useAppSelector(getConfigsWithPriceChange);
	const quantityOption = useAppSelector(getQuantityOption);

	const [changes, setChanges] = useState<ConfigPriceChange[]>([]);

	const formWatch = useWatch();

	useEffect(() => {
		if (!quantityOption) {
			return;
		}
		setChanges(
			configurationPriceModifications(
				quantityOption,
				configs,
				formWatch
			)
		);
	}, [configs, quantityOption, formWatch]);

	const subTotal = reduce(
		(t, [, change]) => t + change,
		quantityOption?.price ?? 0,
		changes
	);

	return {changes, subTotal};
}