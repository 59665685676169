import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Container, Spinner} from 'reactstrap';
import './ProductDetails.css';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {ProductInputList} from '../../input/list/ProductInputList';
import {CurrencyFormatter} from '../../../utils/CurrencyFormatter';
import {
	selectAllInputValues,
	selectCartRequest,
	selectComponentValues,
	selectQuantityValue,
	setActiveProductId
} from "../../input/ProductInputSlice";
import {RequiredToast} from "../../input/utils/RequiredToast";
import {
	useAddProductToCartMutation,
	useGetBulletPointsByIdQuery,
	useGetCartProductsQuery,
	useGetComponentsForSiteProductQuery,
	useGetConfigurationsForSiteProductQuery,
	useGetSiteCategoryByUrlNameOrIdQuery,
	useGetSiteProductByUrlNameQuery,
	useGetSiteProductVariantsBySiteProductIdQuery,
	useRemoveProductFromCartMutation
} from "../../../app/apiSlice";
import {Breadcrumb} from '../../breadcrumb/Breadcrumb';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {Helmet} from "react-helmet-async";
import {CartIdType, selectCartId, setCartId} from "../../cart/CartSlice";
import {SiteProductVariantQuantityOption} from "../SiteProductVariantQuantityOption";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {ImageCarousel} from "../../image/carousel/ImageCarousel";
import {InputClassification} from "../../input/ProductInputValue";
import {calculatePrice, Utils} from "../../../utils/Utils";
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";
import ScrollToTop from "../../helpers/ScrollToTop";
import {showConfirmationModal} from '../../modal/ModalSlice';
import {isCheckProduct} from "../Product";
import PriceGrid from "../PriceGrid";
import {TrustBanner} from "../../trustBanner/TrustBanner";
import {AnalyticsTools} from "../../../utils/AnalyticsHelper";
import {SafetyAndSecurity} from "../../safetyAndSecurity/SafetyAndSecurity";
import {isUsingAdminSite} from "../../../utils/SiteHelper";
import {productConfigurationRoute} from "../../../app/routes";

export const ProductDetailsRoute = () => {
	const navigate = useNavigate();
	const {paramProductLookup} = useParams();
	const {paramSiteCategoryName} = useParams();
	const siteCategoryUrlName = paramSiteCategoryName;

	const goToNextPage = (siteProductVariantId: number, quantityOptionId: number) => {
		navigate(productConfigurationRoute({
			siteProductVariantId,
			quantityOptionId,
		}));
	}

	return (
		<ProductDetails siteProductUrlName={paramProductLookup!} siteCategoryUrlName={siteCategoryUrlName}
		                onGoToNextPage={goToNextPage}/>
	);
}

interface ProductDetailsProps {
	siteProductUrlName: string,
	siteCategoryUrlName?: string,
	consumerId?: number,
	propCartId?: CartIdType,
	onGoToNextPage: (siteProductVariantId: number, quantityOptionId: number) => void,
	onDone?: (cartId: CartIdType) => void,
	siteId?: number,
}

export const ProductDetails = ({
	                               siteProductUrlName,
	                               siteCategoryUrlName,
	                               consumerId,
	                               propCartId,
	                               onGoToNextPage,
	                               onDone,
	                               siteId
                               }: ProductDetailsProps) => {
	const {data: siteCategory} = useGetSiteCategoryByUrlNameOrIdQuery(siteCategoryUrlName ?? skipToken);
	const {
		data: siteProduct,
		isFetching: isFetchingProductByName,
		isError: errorLoadingSiteProduct
	} = useGetSiteProductByUrlNameQuery({siteProductUrlName, siteId});
	const {data: bulletPoints} = useGetBulletPointsByIdQuery(siteProduct?.id ?? skipToken);
	const {data: siteProductVariants} = useGetSiteProductVariantsBySiteProductIdQuery(siteProduct?.id ?? skipToken);
	const {data: components} = useGetComponentsForSiteProductQuery(siteProduct?.id ?? skipToken);
	const {data: configurations} = useGetConfigurationsForSiteProductQuery(siteProduct ? {
		siteProductId: siteProduct.id,
		onlyIncludeInitial: true
	} : skipToken);
	const selectedCartId = useAppSelector(selectCartId);
	const cartId: CartIdType = propCartId ? propCartId : selectedCartId;
	const {data: cartProducts} = useGetCartProductsQuery(cartId);
	const [addProductToCart, {isLoading: isAddingToCart}] = useAddProductToCartMutation();
	const [removeProductFromCart] = useRemoveProductFromCartMutation();
	const quantityOptionId = useAppSelector(selectQuantityValue)?.selectedProductInputOptionId;
	const componentOptionIds = useAppSelector(selectComponentValues).map(cv => cv.selectedProductInputOptionId);
	const cartRequest = useAppSelector(s => selectCartRequest(s, undefined, quantityOptionId, consumerId, cartId));
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [showRequiredFieldToast, setShowRequiredFieldToast] = useState(false);
	const [checkInputs] = useState(false);
	const productInputs = useAppSelector(selectAllInputValues);

	useEffect(() => {
		if (!siteProduct) return;

		dispatch(setActiveProductId(siteProduct.id))
	}, [siteProduct, dispatch]);

	function getPromotionCardImage() {
		if (!siteProduct?.imageId || !siteProduct.images) {
			return <></>
		}
		return (
			<ImageCarousel images={siteProduct!.images} product={siteProduct!}></ImageCarousel>
		);
	}

	function getContinueButtonText() {
		const matchingVariant = findMatchingProductVariant();

		if (!matchingVariant) {
			return "Please select all required options";
		} else {
			return "Buy Now";
		}
	}

	function getSelectedQuantityOption() {
		return siteProduct?.quantityOptions?.find(qo => qo.id === quantityOptionId);
	}

	function getStartingPrice() {
		const selectedQuantityOption = getSelectedQuantityOption();
		if (selectedQuantityOption) {
			const price = calculatePrice(configurations ?? [], productInputs ?? [], selectedQuantityOption);
			return CurrencyFormatter.format(price) ?? "";
		}
	}

	async function confirmRemoveProduct(cartProductId: number) {

		const removedCartProduct = cartProducts?.find(cp => cp.id === cartProductId);
		await removeProductFromCart(cartProductId).unwrap();
		if (removedCartProduct) {
			AnalyticsTools.recordRemoveToCartEvent(removedCartProduct);
		}

		const matchingVariant = findMatchingProductVariant();
		if (matchingVariant?.id) {
			onGoToNextPage(matchingVariant.id, quantityOptionId!);
		}
	}

	function checkCartProductExclusiveRestricted() {
		// If any cart products are cart exclusive and match this product type
		return (siteProduct && cartProducts && cartProducts.some(cp => cp.productTypes.some(pt => pt.isCartExclusive && siteProduct.types.some(t => t.name === pt.name))));
	}

	function promptCheckProductInCart() {

		const checkCartProductIndex = siteProduct && cartProducts && cartProducts.findIndex(cp => cp.productTypes.some(pt => pt.isCartExclusive && siteProduct.types.some(t => t.name === pt.name)))
		const checkCartProductId = checkCartProductIndex !== undefined && checkCartProductIndex > -1 && cartProducts && cartProducts[checkCartProductIndex]
			? cartProducts[checkCartProductIndex].id : null;
		if (checkCartProductId) {
			dispatch(showConfirmationModal({
				title: 'Multiple Check Products',
				content: 'You already have a CHECK order in your cart. We can only process one CHECK order per transaction. Do you want to checkout using the order in the cart or remove the cart order and start over?',
				affirmText: 'Yes, Remove and Continue',
				onConfirm: () => confirmRemoveProduct(checkCartProductId)
			}));
		}
	}

	const onClickContinue = async () => {
		const matchingVariant = findMatchingProductVariant();

		if (matchingVariant?.id && quantityOptionId) {
			if (checkCartProductExclusiveRestricted()) {
				promptCheckProductInCart();
			} else {
				// Add to cart if no configurations
				if (!matchingVariant.hasAdditionalConfiguration) {
					await addToCart(matchingVariant.id);
				}
				// Else go to next page for configuration entry
				else {
					onGoToNextPage(matchingVariant.id, quantityOptionId!);
				}
			}
		} else {
			setShowRequiredFieldToast(true);
			setTimeout(function () {
				setShowRequiredFieldToast(false);
			}, 5000);
		}
	};

	const addToCart = async (siteProductVariantId: number) => {
		try {
			const response = await addProductToCart({...cartRequest, cartId, siteProductVariantId}).unwrap();
			if (response.cartProduct) {
				AnalyticsTools.recordAddToCartEvent(response.cartProduct);
			}
			if (onDone) {
				onDone(response.cartId);
			} else {
				dispatch(setCartId(response.cartId));
				navigate(`/cart`);
			}
		} catch (err) {
			console.error('Failed to save the post: ', err)
		}
	}

	function getActiveQuantityOptions(): SiteProductVariantQuantityOption[] {
		const matchingVariant = findMatchingProductVariant();
		return siteProduct?.quantityOptions
				?.filter(qo => qo.siteProductVariantId === matchingVariant?.id)
				?.sort(Utils.sortBy(qo => qo.quantity))
			?? [];
	}

	const findMatchingProductVariant = () =>
		siteProductVariants?.find(pv =>
			// find the product variant where:
			// all of its components match what we selected
			pv.componentIds?.every(ci => componentOptionIds.includes(ci) &&
				// and all of what we selected matches the variant's components.
				componentOptionIds.every(coi => pv.componentIds?.includes(coi ?? -1))
			));

	const dismissRequiredFieldToast = () => {
		setShowRequiredFieldToast(false);
	}

	const getProductHeader = () => {
		const parentCategoryIndex = siteProduct?.categories?.findIndex(c => c.parentCategoryId === null) ?? -1;
		return siteProduct?.categories ? siteProduct?.categories[parentCategoryIndex].name : '';
	}

	const getDocumentMetadata = () => {

		if (!siteProduct) {
			return null;
		}

		return (
			<Helmet>
				<title>{siteProduct.metaTitle}</title>
				<meta name="description" content={siteProduct.metaDescription}/>
			</Helmet>
		);
	}

	const getConfigurationValueById = (inputClassification: InputClassification, productInputId?: number) => {
		return undefined;
	}

	if (isFetchingProductByName) {
		return <>
			<ScrollToTop/>
			<LoadingSpinner size="sm"/>
		</>
	}

	if (!siteProduct) {
		// Loading product
		if (isFetchingProductByName) return <LoadingSpinner></LoadingSpinner>;
		// Redirect home if not a valid product name
		if (!isFetchingProductByName && errorLoadingSiteProduct) navigate("/");

		return <></>;
	}

	return (
		<>
			<Container className="product-details-outer-container d-flex flex-column">
				{/* Use site category from url or default to first category sorted by name */}
				{!isUsingAdminSite() && <Breadcrumb currentPage={siteProduct.name} siteCategoryId={siteCategory?.id ??
					[...siteProduct.categories ?? []].sort(Utils.sortBy('name', 'desc'))?.find(() => true)?.siteCategoryId}/>}

				{getDocumentMetadata()}

				<div className="product-details-container">

					<div className="product-details-left-content">
						<div className="row-container hide-on-desktop">
							<span className="column-container row-content pull-left">
								<p className="faded-label">{getProductHeader()}</p>
								<h1 className="product-name">{siteProduct.name}</h1>
								{siteProduct?.categories?.some(cat => cat.hidePromotionText) ? null :
									<span className='promotion-text'>{siteProduct.promotionText}</span>}
							</span>
						</div>
						<div className="row-container">
							<span className="column-container row-content">
								<span className="column-container row-content hide-on-desktop">
									<h6 className="faded-label pricing-label-mobile">In Stock</h6>
									<h2 className="price-text-mobile">{getStartingPrice()}</h2>
								</span>
								<span className="product-image-container">
									{getPromotionCardImage()}
								</span>
								<span
									className={`product-detail-container${(siteProduct?.images?.length !== 1) ? ' with-image-controls' : ''}`}>
									<h3>Product Details</h3>
									<div className="product-detail-content">
										<p>{siteProduct.description}</p>
									</div>
									{bulletPoints && <div className="product-bullet-point-list">
                                        <ul>
											{bulletPoints?.map((point) =>
												<li key={point.id}>
													<p className="product-bullet-point-item">{point.pointText}</p>
												</li>)}
                                        </ul>
                                    </div>}
									{isCheckProduct(siteProduct) &&
                                        <PriceGrid siteProduct={siteProduct}/>
									}
									</span>
							</span>
						</div>
					</div>

					<div className="product-details-right-content">
						<span className="white-background">
							<div className="row-container hide-on-mobile">
								<span className="column-container row-content pull-left">
									<h1 className="product-name">{siteProduct.name}</h1>
									{siteProduct?.categories?.some(cat => cat.hidePromotionText) ? null :
										<span className='promotion-text'>{siteProduct.promotionText}</span>}
								</span>
								<span className="column-container pricing-text-above-preview">
									<h6 className="faded-label pricing-label">In Stock</h6>
									<h2 className="price-text">{getStartingPrice()}</h2>
								</span>
							</div>
							<div className="row-container">
								<span className="row-content">
									<ProductInputList
										basePrice={getSelectedQuantityOption()?.price ?? 0}
										configurations={configurations}
										components={components}
										quantityOptions={getActiveQuantityOptions()}
										isInEditMode={true}
										checkInputs={checkInputs}
										siteProductId={siteProduct.id}
										productTypes={siteProduct.types}
										getConfigurationValueById={getConfigurationValueById}
									/>
								</span>
							</div>
						</span>
						<div className="row-container customize-product-row">
							<Button className="continue-button" color="primary" onClick={onClickContinue} disabled={isAddingToCart}>
								{isAddingToCart && <Spinner/>}
								<h6>{getContinueButtonText()}</h6>
							</Button>
						</div>
						<TrustBanner isFullWidth={false} showCheckIcon={siteProduct.types.some(t => /check/i.test(t.name))}/>
					</div>
				</div>

				<RequiredToast isOpen={showRequiredFieldToast} dismissToast={dismissRequiredFieldToast}/>

			</Container>
			<SafetyAndSecurity siteProduct={siteProduct}/>
		</>
	);
};
