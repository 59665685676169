import {SymbolSelectorAccordion} from "./SymbolSelectorAccordion";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {ConfigurationOption} from "../../../components/input/configuration/ConfigurationOption";

interface SymbolSelectorModalProps {
	isOpen: boolean;
	toggle: () => void;
	title?: string;
	options: ConfigurationOption[];
	onOptionClick: (option: ConfigurationOption) => void;
}

export const SymbolSelectorModal = ({isOpen, toggle, title, options, onOptionClick}: SymbolSelectorModalProps) => {
	const closeBtn = <button className='btn-close' onClick={toggle}/>
	return (
		<Modal isOpen={isOpen} toggle={toggle} size="lg">
			<ModalHeader toggle={toggle} close={closeBtn}>
				{title}
			</ModalHeader>
			<ModalBody>
				<SymbolSelectorAccordion options={options} onOptionClick={onOptionClick}/>
			</ModalBody>
		</Modal>
	);
};
