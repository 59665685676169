import {Configuration} from "../../components/input/configuration/Configuration";
import {ReactElement} from "react";
import {SymbolSelectorPreview} from "../inputs/symbolSelector/SymbolSelectorPreview";
import {SignatureMessagePreview} from "../inputs/SignatureMessagePreview";
import {InputTypeName} from "../../components/input/InputType";
import {useFormContext} from "react-hook-form";
import {optionForValue} from "../model";
import classnames from "classnames";
import {FULL_STORY_SECURE_ELEMENT_CLASS} from "../../components/input/ProductInput";

interface ConfigurationEntryPreviewProps {
    readonly config: Configuration;
    readonly displayInfo?: boolean;
    readonly hideNoSelectionMessage: boolean;
}

type ConfigurationEntryPreview = (props: ConfigurationEntryPreviewProps) => ReactElement;

const byInputType: Record<string | InputTypeName, ConfigurationEntryPreview> = {
    'Symbol': SymbolSelectorPreview,
    'AddProductAccessory': NoopEntryPreview,
}

const byConfigKey: Record<string, ConfigurationEntryPreview> = {
    'CustomMessage': SignatureMessagePreview,
    'ReEnterRoutingNumber': NoopEntryPreview,
    'ReEnterBankAccountNumber': NoopEntryPreview,
    'BankAddress': NoopEntryPreview,
}

export function ConfigurationEntryPreviewResolver(props: ConfigurationEntryPreviewProps) {
    const Resolved = byConfigKey[props.config.configurationKey]
        ?? byInputType[props.config.inputType.name];
    
    return Resolved
        ? <Resolved {...props} />
        : <DefaultEntryPreview {...props} />;
}

/**
 * Render a div with "{config label}: {config value}"
 * @param config
 * @constructor
 */
function DefaultEntryPreview({config}: ConfigurationEntryPreviewProps) {
    
    const {getValues} = useFormContext();

    const value = getValues(config.configurationKey);
    const selectedOption = optionForValue(config, value);
    const textValue = selectedOption?.name
        ?? value;
    const valueClass = classnames(
        {
            [FULL_STORY_SECURE_ELEMENT_CLASS]: config.isSecure
        }
    );

    
    return (
        <div className="d-flex flex-row">
            <div>
                {config.label}
            </div>
            <div className={valueClass}>
                : {textValue}
            </div>
        </div>
    );
}

/**
 * Simple component for ignoring configs entry previews.
 * 
 * @param _props
 * @constructor
 */
function NoopEntryPreview(_props: ConfigurationEntryPreviewProps) {
    return <></>;
}