import './SignUp.css';
import React, {ChangeEvent, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Button, Container, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import {useSignUpMutation} from '../../../app/apiSlice';
import {RequiredAsterisk} from "../../input/utils/RequiredAsterisk";
import {EmailInput} from "../../input/email/EmailInput";
import {useAppDispatch} from "../../../app/hooks";
import {showConfirmationModal, showInformationModal} from '../../modal/ModalSlice';
import {isValidSite} from '../../../utils/Utils';
import {REDIRECT_URL_PARAM_NAME} from "../login/constants";

interface SignUpFormErrors {
    email: string;
    firstName: string;
    lastName: string;
}

interface SignUpFormState {
    email: string;
    firstName: string;
    lastName: string;
    errors: SignUpFormErrors
}

export const SignUp = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [signUpMethod] = useSignUpMutation();
    const [showErrors, setShowErrors] = useState(false);
    const [formState, setFormState] = useState<SignUpFormState>({
        email: '',
        firstName: '',
        lastName: '',
        errors: {
            email: '',
            firstName: '',
            lastName: ''
        }
    });
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const isValidatedSite = isValidSite(window.location.hostname);
    const [searchParams] = useSearchParams();
    
    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({...prev, email: e.target.value}));
        validateEmail(e.target.value);
    }

    const onFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({...prev, firstName: e.target.value}));
        validateFirstName(e.target.value);
    }

    const onLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({...prev, lastName: e.target.value}));
        validateLastName(e.target.value);
    }

    const validateEmail = (email: string) => {
        if (email === 'help@checkworks.com' || email === 'customerservice@checkworks.com') {
            formState.errors.email = t(`signUp.errors.emailInvalid`);
        } else {
            formState.errors.email = '';
        }
    }

    const validateFirstName = (firstName: string) => {
        if (firstName.length < 1) {
            formState.errors.firstName = t('signUp.errors.firstNameRequired')
        } else {
            formState.errors.firstName = '';
        }
    }

    const validateLastName = (lastName: string) => {
        if (lastName.length < 1) {
            formState.errors.lastName = t('signUp.errors.lastNameRequired')
        } else {
            formState.errors.lastName = '';
        }
    }

    const onSignUpSuccess = () => {
        const url = searchParams.get(REDIRECT_URL_PARAM_NAME) ?? '/';
        return dispatch(showConfirmationModal({
            title: 'Success!',
            content: 'Check your email to finish setting up your account.',
            onConfirm: () => navigate(url, {replace: true})
        }));
    };

    const onSignUpConflict = () =>
        dispatch(showConfirmationModal({
            title: 'Failed to Sign Up!',
            content: 'The email address you entered is already associated with an account',
            affirmText: 'Forgot your password?',
            onConfirm: () => navigate('/login?tab=forgotPassword'),
        }));

    const onSignUpFail = () =>
        dispatch(showInformationModal({
            title: 'Failed to Sign Up!',
            text: 'Please try again or contact support.'
        }));

    const onSubmit = async (e: React.FormEvent) => {
        setIsSubmitDisabled(true);
        e.preventDefault();
        setShowErrors(true);
        validateEmail(formState.email);
        validateFirstName(formState.firstName);
        validateLastName(formState.lastName);

        if (!formState.email || !formState.firstName || !formState.lastName) {
            setIsSubmitDisabled(false);
            return;
        }
        const errorsNull = Object.values(formState.errors).every(x => x === null || x === '');

        if (errorsNull) {
            try {
                await signUpMethod({
                    email: formState.email,
                    firstName: formState.firstName,
                    lastName: formState.lastName
                }).unwrap();
                onSignUpSuccess();
            } catch (err: any) {
                if (err && err.status === 409) {
                    onSignUpConflict();
                } else {
                    onSignUpFail();
                }
            }
        } else {
            setShowErrors(true);
        }

        setIsSubmitDisabled(false);
    }

    return isValidatedSite ? (
        <span className='sign-up-page d-flex flex-column'>
            <Container className='sign-up-page-container d-flex flex-column'>
                <span className='sign-up-content d-flex'>
                    <span className='sign-up-text d-flex flex-column'>
                        <span className='sign-up-placeholer-title pt-2'>{t(`signUp.leftColumn.title`)}</span>
                        <span className='mt-3'>{t(`signUp.leftColumn.paragraph`)}</span>
                            <ul className='mt-3'>
                                <li>
                                    <span>{t(`signUp.leftColumn.listItem1`)}</span>
                                </li>
                                <li>
                                    <span>{t(`signUp.leftColumn.listItem2`)}</span>
                                </li>
                                <li>
                                    <span>{t(`signUp.leftColumn.listItem3`)}</span>
                                </li>
                                <li>
                                    <span>{t(`signUp.leftColumn.listItem4`)}</span>
                                </li>
                                <li>
                                    <span>{t(`signUp.leftColumn.listItem5`)}</span>
                                </li>
                            </ul>
                    </span>
                    <span className='sign-up-form d-flex flex-column'>
                        <span className='d-flex flex-column mb-4'>
                            <span className='sign-up-title mb-1'>{t(`signUp.title`)}</span>
                            <span>{t(`signUp.subtitle`)}</span>
                        </span>
                        <Form onSubmit={e => onSubmit(e)}>
                            <FormGroup>
                                <Label
                                    for="username">{t(`signUp.emailLabel`)}<RequiredAsterisk></RequiredAsterisk></Label>
                                <EmailInput
                                    required
                                    id="email"
                                    name="email"
                                    value={formState.email}
                                    onChange={(e) => onEmailChange(e)}
                                    invalid={showErrors && !!formState.errors.email}
                                    suppressError={!showErrors}
                                />
                                <FormFeedback>{formState.errors.email}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="firstName">{t(`signUp.firstNameLabel`)}<RequiredAsterisk></RequiredAsterisk></Label>
                                <Input
                                    type="text"
                                    required
                                    id="firstName"
                                    name="firstName"
                                    value={formState.firstName}
                                    onChange={(e) => onFirstNameChange(e)}
                                    invalid={showErrors && !!formState.errors.firstName}
                                />
                                <FormFeedback>{formState.errors.firstName}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label
                                    for="username">{t(`signUp.lastNameLabel`)}<RequiredAsterisk></RequiredAsterisk></Label>
                                <Input
                                    type="text"
                                    required
                                    id="lastName"
                                    name="lastName"
                                    value={formState.lastName}
                                    onChange={(e) => onLastNameChange(e)}
                                    invalid={showErrors && !!formState.errors.lastName}
                                />
                                <FormFeedback>{formState.errors.lastName}</FormFeedback>
                            </FormGroup>
                            <Button color="primary" type='submit' onClick={onSubmit}
                                    disabled={isSubmitDisabled}>{t(`signUp.buttonText`)}</Button>
                        </Form>
                        <span>
                            <Button className='p-0 mt-3' color="link"
                                    onClick={() => navigate('/login/')}>{t(`signUp.signInLink`)}</Button>
                        </span>
                    </span>
                </span>
            </Container>
        </span>

    ) : (
        <></>
    );
}
