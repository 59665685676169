import {useFormContext} from "react-hook-form";
import {PersonalDetailsPreview} from "../PersonalDetailsPreview";
import {ProductConfigurationGroupReviewPanelProps} from "../componentResolution";
import {ReviewPanel} from "./ReviewPanel";

export function PersonalDetailsReviewPanel(_props: ProductConfigurationGroupReviewPanelProps) {
    const {getValues} = useFormContext();

    return (
        <ReviewPanel title="Personal Details Preview">
            <PersonalDetailsPreview
                nameOnAccount={getValues('NameOnAccount')}
                additionalAccountName={getValues('AdditionalAccountName')}
                thirdLineOfInformation={getValues('AddThirdLineOfInformation')}
                street={getValues('CheckAddress.street')}
                city={getValues('CheckAddress.city')}
                state={getValues('CheckAddress.stateCode')}
                zip={getValues('CheckAddress.zip')}
                phoneNumber={getValues('CheckAddress.phoneNumber')}
                font={getValues('ChooseFont')}
            />
        </ReviewPanel>
    )
}