import {useFormContext} from "react-hook-form";
import {PersonalizationPreview} from "../PersonalizationPreview";
import {ProductConfigurationGroupReviewPanelProps} from "../componentResolution";
import {ReviewPanel} from "./ReviewPanel";

export function PersonalizationReviewPanel(_props: ProductConfigurationGroupReviewPanelProps) {
    const {getValues} = useFormContext();
    
    return (
        <ReviewPanel title="Personalization Review">
            <PersonalizationPreview
                line1={getValues('Line1')}
                line2={getValues('Line2')}
                line3={getValues('Line3')}
                line4={getValues('Line4')}
                line5={getValues('Line5')}
                line6={getValues('Line6')}
                justify={getValues('Justify')}
                inkColor={getValues('InkColor')}
                font={getValues('ChooseFont')}
            />
        </ReviewPanel>
    )
}