import "./FontSelector.css"
import {getProductInputOptionDisplayName} from "../input/ProductInput";
import {Utils} from "../../utils/Utils";
import React, {useEffect, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label} from "reactstrap";
import {ConfigurationOption} from "../input/configuration/ConfigurationOption";
import {Control, useController} from "react-hook-form";

interface FontSelectorProps {
	name: string;
	label: string;
	options: ConfigurationOption[];
	control?: Control<any>;
    readonly className?: string;
}

export const FontSelector = ({
                                 name,
                                 label,
                                 options,
                                 control,
                                 className = ''
                             }: FontSelectorProps) => {
    
	const [selectedOptionDisplayName, setSelectedOptionDisplayName] = useState<string>("");
	const [selectedOptionClassName, setSelectedOptionClassName] = useState<string>("");
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const getOptions = () => {
		return (
			[...options]
				.sort(Utils.sortBy('sortOrder', 'asc'))
				.map((option) => {
					const optionDisplayName = getProductInputOptionDisplayName(option);
					const optionClassName = getFontClassName(optionDisplayName);
					return (
						<DropdownItem
							key={option.id}
							className={optionClassName}
							onClick={() => handleSelect(option.value, optionDisplayName, optionClassName)}
						>
							{optionDisplayName}
						</DropdownItem>
					);
				})
		);
	};

	const getDefaultOption = () => {
		const defaultOptionIndex = options.findIndex(o => o.isDefault);
		return options[defaultOptionIndex];
	};

	// Close dropdown on select. Doesn't work inside handleSelect.
	useEffect(() => {
		setIsDropdownOpen(false);
	}, [selectedOptionDisplayName]);

	// RHF integration.
	const {field: {onChange, value}} = useController({
		name,
		control,
		defaultValue: getDefaultOption().value,
	});

	// Set option value (default value or actual value based on what is in React Hook Form).
	useEffect(() => {
		if (value === "") {
			const defaultOption = getDefaultOption();
			const optionValue = defaultOption.value;
			const optionDisplayName = getProductInputOptionDisplayName(defaultOption);
			const optionClassName = getFontClassName(optionDisplayName);
			handleSelect(optionValue, optionDisplayName, optionClassName);
		} else {
			const optionMappedFromValue = options.find((option) => option.value === value);
			if (optionMappedFromValue) {
				const optionDisplayName = getProductInputOptionDisplayName(optionMappedFromValue);
				const optionClassName = getFontClassName(optionDisplayName);
				setSelectedOptionDisplayName(optionDisplayName);
				setSelectedOptionClassName(optionClassName);
			}
		}
	}, [options, value]);

	const handleSelect = (optionValue: string, optionDisplayName: string, optionClassName: string) => {
		setSelectedOptionDisplayName(optionDisplayName);
		setSelectedOptionClassName(optionClassName);
		onChange(optionValue);
	};

	const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState);

	const getFontClassName = (fontName: string) => {
		return "font-" + fontName.toLowerCase().replaceAll(" ", "-");
	};

	const renderLabel = () => {
		return (
			<Label for={name}>
				{label}
			</Label>
		);
	};

    // Remove the font-* classes so the label does not render
    // in the same font
    const formGroupClassNames = className?.replaceAll(/font-/gi, '');

	return (
		<FormGroup className={formGroupClassNames}>
			{renderLabel()}
			<Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
				<DropdownToggle tag="span" className="form-select">
				<span className={selectedOptionClassName}>
					{selectedOptionDisplayName}
				</span>
				</DropdownToggle>
				<DropdownMenu>
					{getOptions()}
				</DropdownMenu>
			</Dropdown>
		</FormGroup>
	);
};
