import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {ProductDetails} from "../../product/details/ProductDetails";
import {ProductSearch} from "./ProductSearch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {CartIdType} from "../../cart/CartSlice";
import {ProductConfigurationPage} from "../../../productConfiguration/ProductConfigurationPage";

export interface ProductConfigurationState {
  siteProductUrlName?: string,
  siteProductVariantId?: number,
  quantityOptionId?: number,
  cartProductId?: number,
  orderProductId?: number,
}

interface FindProductProps {
  isOpen: boolean,
  toggleModal: () => void,
  siteId: number,
  consumerId?: number,
  orderEditCartId?: CartIdType,
  productConfigurationState: ProductConfigurationState,
  onProductConfigurationStateChanged: (state: ProductConfigurationState) => void,
  onProductAddedToCart: (cartId: CartIdType) => void
}
export const FindProduct = ({
  isOpen,
  toggleModal,
  siteId,
  consumerId,
  orderEditCartId,
  productConfigurationState: { siteProductUrlName, siteProductVariantId, quantityOptionId, cartProductId, orderProductId},
  onProductConfigurationStateChanged, onProductAddedToCart
                            }: FindProductProps) => {
  const showConfigurationEntry = !!siteProductVariantId && !!quantityOptionId;
  const goBack = () => {
    // keep only the siteProductUrlName
    if (siteProductVariantId)
      onProductConfigurationStateChanged({
        siteProductUrlName,
        cartProductId,
        orderProductId,
        siteProductVariantId: undefined,
        quantityOptionId: undefined,
      });
    // get rid of everything if we only have a siteProductUrlName
    else if (siteProductUrlName)
      onProductConfigurationStateChanged({});
  };
  
  const goToConfigurationEntry = (siteProductVariantId: number, quantityOptionId: number) => {
    onProductConfigurationStateChanged({
      siteProductUrlName,
      siteProductVariantId,
      quantityOptionId, 
    })
  };
  
  const productAddedToCart = (cartId: CartIdType) => {
    onProductAddedToCart(cartId);
    // close modal and clear everything out
    toggleModal();
    onProductConfigurationStateChanged({}); 
  };

  const cancelChanges = () => {
    toggleModal();
    onProductConfigurationStateChanged({});
  }
  
  const modalNavigation = (
    <div>
      { siteProductUrlName && <FontAwesomeIcon className='modal-back-btn me-2' icon={faArrowLeft} onClick={goBack}/> }
      <button className='btn-close' onClick={toggleModal}/>
    </div>
  ); 
  
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size='xl'>
      <ModalHeader toggle={toggleModal} close={modalNavigation}>{showConfigurationEntry ? 'Customize Your Product' : 'Find Product'}</ModalHeader>
      <ModalBody>
        { !siteProductUrlName && !siteProductVariantId && (!cartProductId || !orderProductId ) &&
            <ProductSearch siteId={siteId} onProductSelected={spid => onProductConfigurationStateChanged({ siteProductUrlName: spid })}/> }
        { siteProductUrlName && !siteProductVariantId &&
            <ProductDetails siteProductUrlName={siteProductUrlName}
                            propCartId={orderEditCartId}
                            onGoToNextPage={goToConfigurationEntry}
                            onDone={productAddedToCart}
                            siteId={siteId}/> }
        { showConfigurationEntry && typeof siteProductVariantId !== 'undefined' &&
            <ProductConfigurationPage
                siteId={siteId}
                siteProductVariantId={siteProductVariantId}
                quantityOptionId={quantityOptionId}
                cartProductId={cartProductId}
                orderProductId={orderProductId}
                onExit={cancelChanges}
                onDone={productAddedToCart}
                isReviewMode={!!orderProductId}
            />
        }
      </ModalBody>
    </Modal>
  );
}