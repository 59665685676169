import {FormInputField} from "../../components/forms/FormInputField";
import {ProductConfigurationInputProps} from "./ProductConfigurationInputProps";
import {useFormContext} from "react-hook-form";
import {MediaQueryTypes, useMediaQuery} from "../../app/hooks";
import cn from "classnames";
import './SignatureMessageInput.css'
import {ChangeEvent, useEffect, useState} from "react";
import {Input, Label} from "reactstrap";
import {SignatureMessagePreview} from "./SignatureMessagePreview";

interface SignatureMessageInputProps extends ProductConfigurationInputProps{

}

export function SignatureMessageInput({config}: SignatureMessageInputProps) {
    const { priceModification: price } = config.configurationDetail;

    const {setValue, getValues, resetField} = useFormContext();

    const [radioValue, setRadioValue] = useState("none");

    function onChangeValue(event : ChangeEvent<HTMLInputElement>) {
        setRadioValue(event.currentTarget.value);
        if (event.currentTarget.value === 'none') {
            setValue(config.configurationKey, '');
        }
    }

    useEffect(() => {
        const signatureMessage = getValues('CustomMessage');
        if (signatureMessage !== undefined && signatureMessage !== "") {
            setRadioValue("custom");
        }
    }, []);

    const noneClass = cn(
        'signature-radio-option',
        {'signature-radio-option-checked': radioValue === 'none'}
    )

    const customClass = cn(
        'signature-radio-option',
        {'signature-radio-option-checked': radioValue === 'custom'}
    )

    const inputLabel = "Message Text (+$" + price + ")";

    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

    const previewClass = cn(
        isMobile ? '' : 'd-flex d-flex-row',
    );

    const messageClass = cn(
        isMobile ? '' : 'col-6',
    );

    function handleOnBlur(val: any) {
        if (!val) {
            setRadioValue("none");
            resetField(config.configurationKey);
        }
    }

    return (
        <>
            <div className="mb-1">{config.label}</div>
            <div className={previewClass}>
                <div className={messageClass}>
                    <div className={noneClass}>
                        <Label>
                            <Input type="radio" name="signature-message" value="none" onChange={onChangeValue}
                                   checked={radioValue === 'none'}/>
                            <span>None</span>
                        </Label>
                    </div>
                    <div className={customClass}>
                        <Label>
                            <Input type="radio" name="signature-message" value="custom" onChange={onChangeValue}
                                   checked={radioValue === 'custom'}/>
                            <span>
                                {inputLabel}
                                <div className="">
                                    <FormInputField
                                        name={config.configurationKey}
                                        label=""
                                        onFocus={() => setRadioValue("custom")}
                                        onBlur={handleOnBlur}
                                    />
                                    <div>{config.helperText}</div>
                                </div>
                            </span>
                        </Label>
                    </div>
                </div>
                <div>
                    <SignatureMessagePreview config={config} hideNoSelectionMessage={true} />
                </div>
            </div>
        </>
    );
}