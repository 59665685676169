import {FieldValues} from "react-hook-form";
import {FormInputField, FormInputProps} from "./FormInputField";
import {FormGroup} from "reactstrap";

type Option = { text: string, value: string | number };

export interface FormSelectProps<Schema extends FieldValues> extends Omit<FormInputProps<Schema>, 'type'> {
	readonly options: ReadonlyArray<Option> | undefined;
}

export const FormSelect = <Schema extends FieldValues>(props: FormSelectProps<Schema>) => {
	const {
		options = [],
		defaultValue = options[0]?.value,
		className = '',
		...rest
	} = props;

	return (
		<FormGroup className={className}>
			<FormInputField {...rest} defaultValue={defaultValue} type="select">
				{options.map((o, i) => (
					<option key={`${o.value}-${i}`} value={o.value}>{o.text}</option>
				))}
			</FormInputField>
		</FormGroup>
	);
};
