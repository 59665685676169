import {Control, useFormContext, useWatch} from "react-hook-form";
import {CheckoutSchema} from "../components/cart/checkout/schema";
import {useEffect} from "react";
import {AddressSchema, isSavedAddress} from "../addresses/AddressSchema";
import {
    getSelectedSavedShippingAddress,
    setSelectedSavedBillingAddress,
    setSelectedSavedPaymentDetails, setShippingAddressIsVerified
} from "../components/order/CheckoutSlice";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
    useGetAddressesByConsumerIdQuery,
    useGetCurrentCartCheckAddressQuery,
    useGetSavedPaymentDetailsQuery
} from "../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {selectCurrentConsumerUser} from "../components/user/login/AuthenticationSlice";

export function useShouldUseShippingAddress() {

    const {setValue, getValues, control} = useFormContext<CheckoutSchema>();

    const savedShippingAddress = useAppSelector(getSelectedSavedShippingAddress);

    const shouldUseShippingAddress = useWatch({
        control,
        name: 'paymentInfo.useShippingAddress'
    });
    const reallyShouldUseShippingAddress = typeof shouldUseShippingAddress === 'boolean'
        ? shouldUseShippingAddress
        : shouldUseShippingAddress === 'true';
    
    useEffect(() => {
        const shippingAddress = getValues('shippingInformation.shippingAddress');
        const addressToUse = isSavedAddress(shippingAddress)
            ? savedShippingAddress
            : shippingAddress;

        if (reallyShouldUseShippingAddress) {
            setValue('paymentInfo.billingAddress', {
                id: -1,
                street: addressToUse?.street || undefined,
                city: addressToUse?.city || undefined,
                stateCode: addressToUse?.stateCode || undefined,
                zip: addressToUse?.zip || undefined
            });
        } else {
            setValue('paymentInfo.billingAddress', {
                id: -1,
                street: '',
                city: '',
                stateCode: '',
                zip: ''
            });
        }
    }, [reallyShouldUseShippingAddress]);

    return reallyShouldUseShippingAddress;
}

export function useDispatchSavedBillingAddressSelected() {

    const dispatch = useAppDispatch();

    const consumer = useAppSelector(selectCurrentConsumerUser);

    const {data: savedAddresses = []} = useGetAddressesByConsumerIdQuery(consumer?.id ?? skipToken);

    const {watch, getValues} = useFormContext<CheckoutSchema>();

    let billingAddressId = watch('paymentInfo.billingAddress.id');

    useEffect(() => {

        const address = getValues('paymentInfo.billingAddress') as AddressSchema;

        if (!address) return;

        if (!isSavedAddress(address)) {
            dispatch(setSelectedSavedBillingAddress(null));
            return;
        }

        const savedAddress = savedAddresses.find(a => a.id == address.id);

        if (savedAddress) {
            dispatch(setSelectedSavedBillingAddress(savedAddress));
        }
    }, [savedAddresses, billingAddressId]);
}

export function useDispatchSavedPaymentMethodSelected() {
    const dispatch = useAppDispatch();

    const {data: savedPaymentMethods = []} = useGetSavedPaymentDetailsQuery()

    const {watch, getValues} = useFormContext<CheckoutSchema>();

    const watchedPaymentProfileId = watch('paymentInfo.paymentProfileId');
    useEffect(() => {
        const paymentProfileId = getValues('paymentInfo.paymentProfileId');

        if (paymentProfileId === '') {
            setSelectedSavedPaymentDetails(null);
        } else {
            const spm = savedPaymentMethods.find((savedPaymentMethod) => {
                return savedPaymentMethod.paymentProfileId === paymentProfileId;
            });
            if (spm) {
                dispatch(setSelectedSavedPaymentDetails(spm));
            }
        }
    }, [watchedPaymentProfileId]);
}

export function useShouldUseCheckAddress(control: Control<CheckoutSchema>) {
    const dispatch = useAppDispatch();

    const {data: checkAddress} = useGetCurrentCartCheckAddressQuery();

    const {setValue} = useFormContext<CheckoutSchema>();

    const shouldUseCheckAddress = useWatch({
        control,
        name: 'shippingInformation.useCheckAddress',
    });

    // I am unable to trace down why this is sometimes set to a string
    const reallyShouldUseCheckAddress = typeof shouldUseCheckAddress === 'boolean'
        ? shouldUseCheckAddress
        : shouldUseCheckAddress === 'true';

    useEffect(() => {

        if (!checkAddress || !reallyShouldUseCheckAddress) {
            setValue('shippingInformation.shippingAddress.street', '');
            setValue('shippingInformation.shippingAddress.city', '');
            setValue('shippingInformation.shippingAddress.stateCode', '');
            setValue('shippingInformation.shippingAddress.zip', '');
            dispatch(setShippingAddressIsVerified(false));
            return;
        }
        
        setValue('shippingInformation.shippingAddress', {
            id: -1,
            street: checkAddress.street || undefined,
            city: checkAddress.city || undefined,
            stateCode: checkAddress.stateCode || undefined,
            zip: checkAddress.zip || undefined
        });
        dispatch(setShippingAddressIsVerified(checkAddress.isVerified ?? false));

    }, [reallyShouldUseCheckAddress, checkAddress]);

    return reallyShouldUseCheckAddress;
}