import {useGetComponentsForVariantQuery} from "../app/apiSlice";
import {useAppSelector} from "../app/hooks";
import {getPrice, getQuantityOption} from "./ProductConfigurationSlice";
import {SiteProductVariant} from "../components/product/SiteProductVariant";
import {DisplayImage} from "../components/image/display/DisplayImage";
import {selectCurrentConsumerUser} from "../components/user/login/AuthenticationSlice";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {REDIRECT_URL_PARAM_NAME, SIGNUP_PAGE_URL} from "../components/user/login/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faUserPlus} from '@fortawesome/free-solid-svg-icons';
import {productDetailsRoute} from "../app/routes";

interface ProductConfigurationBannerProps {
	siteProductVariant: SiteProductVariant;
	showCheckoutSignIn: boolean;
}

export const ProductConfigurationBanner = ({
	                                           siteProductVariant,
	                                           showCheckoutSignIn,
                                           }: ProductConfigurationBannerProps) => {
	const price = useAppSelector(getPrice);
	const quantityOption = useAppSelector(getQuantityOption);
	const consumer = useAppSelector(selectCurrentConsumerUser);
	const {data: components} = useGetComponentsForVariantQuery(siteProductVariant.id);

	const getComponentOptionValues = () =>
		components?.filter(c => c.componentOptions).map(component =>
			component?.componentOptions?.map(componentOption => (
				<span key={componentOption.value}>{componentOption.description} </span>
			))
		);

	const renderVariantQuantityOptions = () => {
		const changePath = productDetailsRoute({
			paramProductLookup: siteProductVariant?.urlName ?? null,
		});
		return (
			<>
				{getComponentOptionValues()}
				{components && components.length > 0 ? (<span className="separator-dash">-</span>) : <></>}
				{quantityOption?.description}
				<Link to={changePath}
				      className="ms-3 fs-6">
					Change
				</Link>
			</>
		);
	};

	const renderThumbnail = () => {
		return (
			<div className="product-preview-image pe-none">
				<DisplayImage imageId={siteProductVariant.imageId} shouldLimit={false}/>
			</div>
		);
	};

	return (
		<div className="container-fluid">
			<div className="row gap-3 mb-0">
				<div className="col g-0 bg-white border">

					<div className="container-fluid">
						<div className="row mb-0">
							<div className="col g-0">

								<div className="container-fluid">
									<div className="row mb-0">
										<div className="col-12 col-lg-5 g-0 p-3">
											{renderThumbnail()}
										</div>
										<div className="col-12 col-lg-7 g-0 p-3">
											<div className="h4">
												{siteProductVariant.name}
											</div>
											<div>
												{renderVariantQuantityOptions()}
											</div>
											<div className="opacity-25 d-none d-sm-block">
												<small>SKU: {siteProductVariant.sku}</small>
											</div>
										</div>
									</div>
								</div>

							</div>
							<div className="col-3 g-0">
								<div className="h4 float-end p-3 pe-4">
									{price}
								</div>
							</div>
						</div>
					</div>

				</div>
				{!consumer && showCheckoutSignIn &&
                    <SignInPanel/>
				}
			</div>
		</div>
	);
};

function SignInPanel() {
	const {t} = useTranslation();
	const loginPageUrl = useLoginUrl();
	const signUpPageUrl = useSignUpUrl();

	return (
		<div className="col-sm-4 g-0 border bg-primary bg-opacity-25 d-flex justify-content-center align-items-center">
			<div className="text-center p-2">
				<div className="fw-bold mb-0 fs-5">
					{t('checkoutSignIn.title')}
				</div>
				<div className="text-muted lh-sm mb-0">
					<small>{t('checkoutSignIn.body')}</small>
				</div>
				<div className="p-2 mx-2">
					<Link to={loginPageUrl} className="btn btn-sm btn-secondary" replace>
						<FontAwesomeIcon icon={faUser}/>
						Sign In
					</Link>
					<div className="text-muted lh-sm">
						<small>- or -</small>
					</div>
					<Link to={signUpPageUrl} replace>
						<FontAwesomeIcon icon={faUserPlus} className="me-1"/>
						Create an Account
					</Link>
				</div>
			</div>
		</div>
	);
}

function useLoginUrl() {
	const {pathname} = useLocation();
	const loginPageUrl = new URL(`${window.location.origin}/login`);
	loginPageUrl.searchParams.set(REDIRECT_URL_PARAM_NAME, pathname);
	return loginPageUrl.href;
}

function useSignUpUrl() {
	const {pathname} = useLocation();
	const loginPageUrl = new URL(`${window.location.origin}${SIGNUP_PAGE_URL}`);
	loginPageUrl.searchParams.set(REDIRECT_URL_PARAM_NAME, pathname);
	return loginPageUrl.href;
}
