import './LoginForm.css';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Alert, Button, Form, FormFeedback, FormGroup, Input, Label, Spinner} from 'reactstrap';
import {
    useAuthenticateMutation, useGetCartProductsQuery,
    useSendForgotPasswordEmailMutation, useSignInCartCheckMutation,
} from '../../../../app/apiSlice';
import {useAppDispatch, useAppSelector} from '../../../../app/hooks';
import AuthenticationRequest from '../AuthenticationRequest';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {selectCartId, setCartId} from "../../../cart/CartSlice";
import {LoginTab} from "./LoginTab";
import { EmailInput } from "../../../input/email/EmailInput";
import ScrollToTop from "../../../helpers/ScrollToTop";
import {getErrorMessage} from "../../../../utils/Utils";
import {showConfirmationModal} from "../../../modal/ModalSlice";

interface LoginFormErrors {
    email: string;
    password: string;
}

interface UserLoginProps {
    title?: string;
    showSignUp?: boolean;
    redirectLink: string;
}

export const LoginForm = (props: UserLoginProps) => {
    const { t } = useTranslation();
    const [authMethod, {isLoading: isSigningIn}] = useAuthenticateMutation();
    const [signInCartCheck] = useSignInCartCheckMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [informationMessage, setInformationMessage] = useState('');
    const [isMessageError, setIsMessageError] = useState(true);
    const [passwordShown, setPasswordShown] = useState(false);
    const [ lockForgotPassword, setLockForgotPassword ] = useState(false);
    const [forgotPasswordMutation, {isLoading: isSubmittingForgotPassword}] = useSendForgotPasswordEmailMutation();
    const [searchParams] = useSearchParams();
    const [selectedLoginTab, setSelectedLoginTab] =
        useState<LoginTab>(() => getLoginTabFromSearchParams());
    const cartId = useAppSelector(selectCartId);

    const [formState, setFormState] = useState<AuthenticationRequest>({Email: "", Password: ""});
    const [errors, setErrors] = useState<LoginFormErrors>({
        email: '',
        password: ''
    });
    const [ showErrors, setShowErrors ] = useState(false);

    useEffect(() => {
        const searchParamTab = getLoginTabFromSearchParams();
        if (searchParamTab === selectedLoginTab)
            return;

        setSelectedLoginTab(searchParamTab);
    }, [searchParams]);

    function getLoginTabFromSearchParams() {
        const urlTab = searchParams.get("tab");
        switch (urlTab) {
            case 'forgotPassword':
                return LoginTab.ForgotPassword;
            default:
                return LoginTab.Login;
        }
    }

    const onLogin = async (event: React.FormEvent, bypassCartCheck: boolean = false) => {
        event.preventDefault();
        setShowErrors(true);
        
        validatePassword(formState.Password);
        const errorsNull = Object.values(errors).every(x => x === null || x === '');
        
        if (!formState.Email || !formState.Password) return;
        if (errorsNull) {
            try {
                // This is used to check for cart conflicts, and is no indication of valid credentials
                if (cartId && !bypassCartCheck) {
                    await signInCartCheck(formState).unwrap();
                }

                const res = await authMethod(formState).unwrap();
                if (res.cartId) {
                    dispatch(setCartId(res.cartId));
                }
                navigate(props.redirectLink, { replace: true });
                
            } catch (e: any) {
                if (e && e.status === 409) {
                    dispatch(showConfirmationModal({
                        title: t('signIn.cartConflicts.title'),
                        content: t('signIn.cartConflicts.content'),
                        affirmText: t('signIn.cartConflicts.affirmText'),
                        onConfirm: () => onLogin(event, true)
                    }));
                }
                else {
                    setIsMessageError(true);
                    setInformationMessage(getErrorMessage(e) ?? t('signIn.errors.attemptFailure'));
                }
            }
        }
    }

    const onForgotPasswordClick = () => {
        setIsMessageError(false);
        setInformationMessage('');
        setSelectedLoginTab(LoginTab.ForgotPassword);
        setFormState({Email: "", Password: ""});
    }

    const onSubmitForgotPasswordCancelClick = () => {
        setIsMessageError(false);
        setInformationMessage('');
        setSelectedLoginTab(LoginTab.Login);
        setFormState({Email: "", Password: ""});
    }

    const onSubmitForgotPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        setShowErrors(true);
        const errorsNull = Object.values(errors).every(x => x === null || x === '');

        if (!formState.Email) return;
        if (errorsNull) {
            try {
                await forgotPasswordMutation({email: formState.Email});
                setIsMessageError(false);
                setInformationMessage(t('forgotPassword.attempt.successMessage'));
                setLockForgotPassword(true);

            } catch (e: any) {
                setIsMessageError(true);
                setInformationMessage(t('forgotPassword.attempt.errorMessage'));
            }
        }
    }

    const validatePassword = (password: string) => {
        if (!password) {
            setErrors({password: t(`signIn.errors.password`), email: errors.email});
        } else {
            errors.password = '';
        }
    }

    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, Password: e.target.value }));
        validatePassword(e.target.value);
    }

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, Email: e.target.value }));
    }

    const getMessageBox = () => {
        if (informationMessage) {
            return (
                <Alert color={isMessageError ? 'danger' : 'success'}>
                    <span>{informationMessage}</span>
                </Alert>
            );
        } else {
            return <></>
        }
    }

    const renderSignUp = () => {
        if (props.showSignUp || props.showSignUp === undefined) {
            return <Button className='p-0 mt-2' color="link" onClick={() => navigate('/sign-up/')}>{t(`signIn.signUpLink`)}</Button>
        } else {
           return <></>
        }
    }
    
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    }

    const renderSigninForm = () => (
        <span className='d-flex flex-column'>
            <span className='form-title'>
                {props.title ? props.title : t(`signIn.title`)}
            </span>
            <Form onSubmit={e => onLogin(e)}>
                <FormGroup>
                    <Label htmlFor='emailAddress'>{t(`signIn.emailLabel`)}</Label>
                    <EmailInput id="emailAddress" onChange={onEmailChange} invalid={!!errors.email} value={formState.Email} suppressError={!showErrors} />
                    <FormFeedback>{errors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor='password'>{t(`signIn.passwordLabel`)}</Label>
                    <div className='password-input-group'>
                        <FontAwesomeIcon
                            className='password-show-icon'
                            style={{top: `calc(${!!errors.password ? '30%' : '50%'} - 0.5em)`}}
                            icon={passwordShown ? faEyeSlash : faEye}
                            onClick={togglePassword}
                        />
                        <Input
                            id="password-input"
                            autoComplete="off"
                            type={passwordShown ? "text" : "password"}
                            onChange={onPasswordChange}
                            invalid={!!errors.password} />
                        <FormFeedback>{errors.password}</FormFeedback>
                    </div>
                    <span>
                        <Button className='sign-in-forgot-password-link p-0'
                                type='button'
                                color="link"
                                onClick={() => onForgotPasswordClick()}>
                            {t(`signIn.forgotPassword`)}
                        </Button>
                    </span>
                </FormGroup>
                {getMessageBox()}
                <span className='container'>
                    <span className="mt-1 d-flex">
                        <Button type='submit'
                                disabled={isSigningIn}
                                color='primary'>
                            {isSigningIn && <Spinner/>}
                            {t(`signIn.buttonText`)}
                        </Button>
                    </span>
                </span>
            </Form>
            <span>
                {renderSignUp()}
            </span>
        </span>
    )

    const renderForgotPasswordForm = () => (
        <div>
            <div className="d-flex flex-column">
                <span className='form-title'>
                    {props.title ? props.title : t(`forgotPassword.title`)}
                </span>
                <Form onSubmit={e => onSubmitForgotPassword(e)}>
                    <FormGroup>
                        <Label htmlFor='emailAddress'>{t(`forgotPassword.emailLabel`)}</Label>
                        <EmailInput id="emailAddress" onChange={onEmailChange} disabled={lockForgotPassword} invalid={!!errors.email} value={formState.Email} suppressError={!showErrors} />
                        <FormFeedback>{errors.email}</FormFeedback>
                    </FormGroup>
                    {getMessageBox()}
                    <span className='container d-flex forgot-password-btn-group'>
                        <span className="mt-1 d-flex">
                            <Button type='submit'
                                    disabled={isSubmittingForgotPassword || lockForgotPassword}
                                    color='primary'>
                                {isSubmittingForgotPassword && <Spinner/>}
                                {t(`forgotPassword.buttonText`)}
                            </Button>
                        </span>
                        <span className="mt-1 d-flex">
                            <Button type='button' onClick={onSubmitForgotPasswordCancelClick} color='secondary'>{t(`forgotPassword.cancelButtonText`)}</Button>
                        </span>
                    </span>
                </Form>
            </div>
        </div>
    )

    const getActiveTab = () => {
        switch(selectedLoginTab) {
            case LoginTab.ForgotPassword:
                return renderForgotPasswordForm();
            case LoginTab.Login:
                return renderSigninForm();
        }
    }

    return (
        <div>
            <ScrollToTop></ScrollToTop>
            {getActiveTab()}
        </div>
    );
}