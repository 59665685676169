import {MediaQueryTypes, useMediaQuery} from "../app/hooks";
import {ConfigurationEntryResolver} from "./ConfigurationEntryResolver";
import {ConfigurationGroupPreview} from "./ConfigurationGroupPreview";
import cn from "classnames";
import {Configuration} from "../components/input/configuration/Configuration";
import {ProductConfigurationGroup} from "./model";

interface ConfigurationEntryGroupProps {
    readonly group: ProductConfigurationGroup;
    readonly showLabels?: boolean;
    readonly onFieldBlur?: (config: Configuration) => void | Promise<void>;
}

export function ConfigurationEntryGroup({
                                            group,
                                            showLabels = true,
                                            onFieldBlur = () => void 0,
                                        }: ConfigurationEntryGroupProps) {

    const configs = group.configs;

    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

    const showPreview = ['PersonalDetails', 'Personalization'].includes(group.groupKey);

    const sectionClassName = cn({
        'd-flex flex-row': showPreview && !isMobile
    });

    const configClassName = cn(
        'row', {
            'col-6': showPreview && !isMobile
        });

    return (
        <div className="configuration-entry-group">
            {showLabels && <header className="d-flex flex-row align-items-baseline">
                <h2>{group.name}</h2>
                {group.actionLabel &&
                    <p className="text-muted fs-4 ms-2">- {group.actionLabel}</p>
                }
            </header>}
            <div className={sectionClassName}>
                <div className={configClassName}>
                    {configs.map(c => (
                        <ConfigurationEntryResolver config={c}
                                                    onFieldBlur={onFieldBlur}
                                                    key={c.configurationKey}
                        />
                    ))}
                </div>
                {showPreview && <ConfigurationGroupPreview group={group}/>}
            </div>
        </div>
    );
}