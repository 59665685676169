import {ProductConfigurationGroup} from "../model";
import {PersonalDetailsReviewPanel} from "./PersonalDetailsReviewPanel";
import {PersonalizationReviewPanel} from "./PersonalizationReviewPanel";
import {
    ConfigurationEntryPreviewResolver,
    ProductConfigurationGroupReviewPanel,
    ProductConfigurationGroupReviewPanelProps
} from "../componentResolution";
import {useId} from "react";

interface ConfigurationGroupReviewResolverProps {
    group: ProductConfigurationGroup;
}

export function ConfigurationGroupReviewResolver({group}: ConfigurationGroupReviewResolverProps) {
    return resolve(group)
}

const components: Record<string, ProductConfigurationGroupReviewPanel> = {
    'PersonalDetails': PersonalDetailsReviewPanel,
    'Personalization': PersonalizationReviewPanel,
};

function resolve(group: ProductConfigurationGroup) {
    const Resolved = components[group.groupKey];

    return Resolved
        ? <Resolved group={group}/>
        : <DefaultGroupReviewPanel group={group}/>
}

export function DefaultGroupReviewPanel({group: {configs}}: ProductConfigurationGroupReviewPanelProps) {
    const uid = useId();
    return (
        <div>
            {configs.map((config, index) => (
                <div key={`${uid}-${index}`}>
                    <ConfigurationEntryPreviewResolver config={config}
                                                       displayInfo={false}
                                                       hideNoSelectionMessage={false} />
                </div>
            ))}
        </div>
    )
}
