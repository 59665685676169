import {Configuration} from "../components/input/configuration/Configuration";
import {FieldValues} from "react-hook-form";
import {RuleTypes} from "../components/input/configuration/rule/ConfigurationRule";

const CSS_FIELD_MODIFIERS = [
    "width-half"
    , "width-full"
    , "font-block"
    , "font-classic-condensed"
    , "font-title-plate"
    , "font-,calligraphy"
    , "font-flair"
    , "font-garamond"
    , "font-handwriting"
    , "font-executive"
    , "font-classic-block"
    , "font-broadway"
    , "font-excalibur"
    , "mt-0"
] as const;

type CssFieldModifier = typeof CSS_FIELD_MODIFIERS[number];

function isCssFieldModifier(val: any): val is CssFieldModifier {
    return val !== undefined && val !== null &&
        CSS_FIELD_MODIFIERS.includes(val);
}

const FIELD_MODIFIER_VALUES = [
    ...CSS_FIELD_MODIFIERS
    , "disable-auto-complete"
    , "name-auto-complete"
    , "address-auto-complete"
    , "disable-entry"
    , "wait-to-validate-until-onblur"
] as const;

type FieldModifierValue = typeof FIELD_MODIFIER_VALUES[number];

function isValidFieldModifier(val: any): val is FieldModifierValue {
    return val !== null && val !== undefined
        ? FIELD_MODIFIER_VALUES.includes(val)
        : false;
}

const fieldModifierToCssClass: Record<CssFieldModifier | string, string> = {
    'width-half': 'half-width-text-input me-4',
    'width-full': 'full-width-text-input',
}
const defaultIfNotSpecified: readonly FieldModifierValue[] = [
    'width-half',
    'width-full'
];

function fieldModifierClassNames({fieldModifiers, configurationKey}: Configuration): string[] {
    const classNames = fieldModifiers.reduce((classNames, fieldModifier) => {
        if (isCssFieldModifier(fieldModifier.value)) {
            return classNames.add(
                fieldModifierToCssClass[fieldModifier.value]
                ?? fieldModifier.value
            );
        }
        return classNames;
    }, new Set<string>());

    // default all fields to full width
    const hasWidth = fieldModifiers
        .some(({value}) =>
            defaultIfNotSpecified.includes(value as FieldModifierValue));
    if (!hasWidth) {
        classNames.add(fieldModifierToCssClass['width-full'])
    }
    
    return Array.from(classNames);
}

const isFieldDisabled = ({fieldModifiers}: Configuration) =>
    fieldModifiers.some(({value}) => value === 'disable-entry');

export const isFieldDisabledFromReferencedConfigs = ({
    config,
    allConfigs,
    formValues,
}: { config: Configuration, allConfigs: Configuration[], formValues: FieldValues }) => {
    const rule = config.rules.find(r => r.configurationRuleType.name === RuleTypes.DisabledWhenValueExists);
    const refKey = allConfigs.find(c => c.configurationDetail.id === rule?.referenceConfigurationDetailId)?.configurationKey;
    if (!refKey) {
        return {
            isDisabled: false,
            ruleFailedMessage: undefined,
        };
    }
    
    return {
        isDisabled: formValues[refKey],
        ruleFailedMessage: rule?.ruleFailedMessage,
    };
}

export function fieldModifierAttributes(config: Configuration) {
    return {
        className: fieldModifierClassNames(config).join(' '),
        disabled: isFieldDisabled(config)
    }
}

export function hasFieldModifier(config: Configuration, fm: FieldModifierValue): boolean {
    return config.fieldModifiers.some(fms => fms.value === fm);
}