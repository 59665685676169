import cn from "classnames";
import {useFormContext} from "react-hook-form";
import {getFontClassName} from "../../utils/FontUtils";
import React from "react";
import {Configuration} from "../../components/input/configuration/Configuration";
import {isEmpty} from "lodash";
import {ReviewPanel} from "../review/ReviewPanel";

interface SignatureMessagePreviewProps {
    displayInfo?: boolean;
    readonly config: Configuration;
    readonly hideNoSelectionMessage: boolean;
}

export function SignatureMessagePreview({
                                            displayInfo = true,
                                            config,
                                            hideNoSelectionMessage
                                        }: SignatureMessagePreviewProps) {
    const {getValues} = useFormContext();

    const currentValue = getValues(config.configurationKey);
    const hasValue = !isEmpty(currentValue);
    const selectedFont = getValues(CONFIG_KEY_FONT);
    
    const previewClass = cn(
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'signature-message-preview', {
            [getFontClassName(selectedFont) ?? Symbol('font')]: hasValue,
        }
    );

    const previewInfo = () => {
        return "The text previewed above will appear above the signature area on your check. Your Custom Signature Message will use the same font as your Personal Details.";
    }

    return (
        <ReviewPanel title={'Signature Message Preview'}
                     infoLabel={previewInfo()}
                     showInfoLabel={displayInfo}>
            <div className={previewClass}>
                {hasValue
                    ? currentValue
                    : !hideNoSelectionMessage && NO_SELECTION_MESSAGE}
            </div>
        </ReviewPanel>
    )
}

const NO_SELECTION_MESSAGE = 'No custom message entered';
const CONFIG_KEY_FONT = 'ChooseFont';