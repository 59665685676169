import './PersonalizationPreview.css'
import cn from "classnames";
import {getFontClassName} from "../utils/FontUtils";

interface PersonalizationPreviewProps {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    line5: string;
    line6?: string;
    justify?: string;
    inkColor?: string;
    font?: string;
}

export function PersonalizationPreview({line1, line2, line3, line4, line5, line6, justify, inkColor, font}: PersonalizationPreviewProps) {
    const justifyStyle = justify ? justify === 'left' ? 'text-left' : justify === 'center' ? 'text-center' : 'text-right' : 'text-center';
    const inkColorStyle = inkColor ? inkColor === 'black' ? 'text-black' : 'text-danger' : 'text-black';

    const previewClass = cn(
        'p-1',
        'personalization-preview',
        justifyStyle,
        inkColorStyle,
        font && getFontClassName(font)
    );
    return (
        <div className={previewClass}>
            <div>{line1}</div>
            <div>{line2}</div>
            <div>{line3}</div>
            <div>{line4}</div>
            <div>{line5}</div>
            {line6 && <div>{line6}</div>}
        </div>
    )
}