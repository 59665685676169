import {FieldValues} from "react-hook-form";
import {Configuration} from "../components/input/configuration/Configuration";
import {useAppSelector} from "../app/hooks";
import {getQuantityOption, getSiteProductVariant} from "./ProductConfigurationSlice";

/*
* TODO: DEVELOPER NOTE (re: PBI 2848)
* To persist configuration form data within an item type, see the commented code
* lines herein. If we decide not to do so, the commented sections may be removed.
*/

// Constants.

export const PRODUCT_CONFIG_STORAGE_KEYS = {
	//ITEM_TYPE_ID: "ProductConfig_ItemTypeId",
	SITE_PRODUCT_ID: "ProductConfig_SiteProductId",
	SITE_PRODUCT_VARIANT_ID: "ProductConfig_SiteProductVariantId",
	QUANTITY_OPTION_ID: "ProductConfig_QuantityOptionId",
	FIELD_VALUES: "ProductConfig_ConfigurationFieldValues"
};

// Interfaces.

interface StorePersistentFormDataProps {
	fieldValues: FieldValues;
}

interface RetrievePersistentFormDataProps {
	configurations: Configuration[];
}

// Hook.

/* Tools for managing product configuration form data in session storage. */
export const usePersistentFormData = () => {
	// Get values from slice.
	const quantityOptionId = useAppSelector(getQuantityOption)?.id ?? 0;
	const siteProductVariant = useAppSelector(getSiteProductVariant);
	const siteProductVariantId = siteProductVariant?.id ?? 0;
	//const itemTypeId = siteProductVariant?.itemType.id ?? 0;
	const siteProductId = siteProductVariant?.siteProductId ?? 0;

	const storePersistentFormData = ({fieldValues}: StorePersistentFormDataProps) => {
		if (siteProductVariantId && quantityOptionId) {
			//sessionStorage.setItem(PRODUCT_CONFIG_STORAGE_KEYS.ITEM_TYPE_ID, itemTypeId.toString());
			sessionStorage.setItem(PRODUCT_CONFIG_STORAGE_KEYS.SITE_PRODUCT_ID, siteProductId.toString());
			sessionStorage.setItem(PRODUCT_CONFIG_STORAGE_KEYS.SITE_PRODUCT_VARIANT_ID, siteProductVariantId.toString());
			sessionStorage.setItem(PRODUCT_CONFIG_STORAGE_KEYS.QUANTITY_OPTION_ID, quantityOptionId.toString());
			sessionStorage.setItem(PRODUCT_CONFIG_STORAGE_KEYS.FIELD_VALUES, JSON.stringify(fieldValues));
		}
	};

	const retrievePersistentFormData = ({configurations}: RetrievePersistentFormDataProps) => {
		// Pull values out of storage.
		//const storedItemTypeId =
		//	Number.parseInt(sessionStorage.getItem(PRODUCT_CONFIG_STORAGE_KEYS.ITEM_TYPE_ID) ?? "0");
		const storedSiteProductId =
			Number.parseInt(sessionStorage.getItem(PRODUCT_CONFIG_STORAGE_KEYS.SITE_PRODUCT_ID) ?? "0");
		const storedSiteProductVariantId =
			Number.parseInt(sessionStorage.getItem(PRODUCT_CONFIG_STORAGE_KEYS.SITE_PRODUCT_VARIANT_ID) ?? "0");
		const storedQuantityOptionId =
			Number.parseInt(sessionStorage.getItem(PRODUCT_CONFIG_STORAGE_KEYS.QUANTITY_OPTION_ID) ?? "0");
		const storedFieldValues = sessionStorage.getItem(PRODUCT_CONFIG_STORAGE_KEYS.FIELD_VALUES);

		// If all values match, restore all form data.
		// 2848: To persist data across an item type, add: itemTypeId === storedItemTypeId
		if (siteProductId === storedSiteProductId
			&& siteProductVariantId === storedSiteProductVariantId
			&& quantityOptionId === storedQuantityOptionId
		) {
			if (storedFieldValues) {
				return JSON.parse(storedFieldValues);
			}
		}

		// If SiteProductId matches but no other values do, restore only valid form data.
		// 2848: To persist data across an item type, instead use: itemTypeId === storedItemTypeId
		if (siteProductId === storedSiteProductId) {
			if (storedFieldValues) {
				return cleanFieldValues(JSON.parse(storedFieldValues), configurations);
			}
		}

		// If nothing matches, clear storage.
		clearPersistentFormData();
		return;
	};

	const clearPersistentFormData = () => {
		//sessionStorage.removeItem(PRODUCT_CONFIG_STORAGE_KEYS.ITEM_TYPE_ID);
		sessionStorage.removeItem(PRODUCT_CONFIG_STORAGE_KEYS.SITE_PRODUCT_ID);
		sessionStorage.removeItem(PRODUCT_CONFIG_STORAGE_KEYS.SITE_PRODUCT_VARIANT_ID);
		sessionStorage.removeItem(PRODUCT_CONFIG_STORAGE_KEYS.QUANTITY_OPTION_ID);
		sessionStorage.removeItem(PRODUCT_CONFIG_STORAGE_KEYS.FIELD_VALUES);
	};

	return {
		storePersistentFormData,
		retrievePersistentFormData,
		clearPersistentFormData
	};
};

// Utilities.

const cleanFieldValues = (fieldValues: FieldValues, configurations: Configuration[]) => {
	// Remove any field values that don't have a corresponding configuration.
	for (const key in fieldValues) {
		if (fieldValues.hasOwnProperty(key) && fieldValues[key]) {
			const configuration = configurations.find((c) => c.configurationKey === key);
			if (configuration && configuration.id <= 0) {
				delete fieldValues[key];
			}
		}
	}
	return fieldValues;
};
