import {ConfigurationEntryPageProps, DefaultConfigurationEntryPage} from "./DefaultConfigurationEntryPage";
import {ReactElement} from "react";
import {BankingInformationEntryPage} from "./bankingInformation/BankingInformationEntryPage";
import {OnFieldBlurHandler} from "./validation/types";
import {ProductConfigurationPage} from "./model";
import {ReviewPage} from "../components/input/configuration/ConfigurationPaging";
import {ConfigurationReviewPage} from "./review/ConfigurationReviewPage";

interface ConfigurationEntryPageResolverProps {
    readonly page: ProductConfigurationPage;
    readonly onFieldBlur?: OnFieldBlurHandler;
    isReviewMode: boolean;
    siteId?: number;
}

export function ConfigurationEntryPageResolver({
                                                   page,
                                                   onFieldBlur = () => void 0,
                                                   isReviewMode = false,
                                                    siteId
                                               }: ConfigurationEntryPageResolverProps) {
    if (page) {
        return resolve(page, onFieldBlur, isReviewMode, siteId);
    }

    return null;
}

const components: Record<string, (p: ConfigurationEntryPageProps) => ReactElement> = {
    'CheckBankingInformation': BankingInformationEntryPage,
    [ReviewPage.pagingKey]: ConfigurationReviewPage,
};

function resolve(page: ProductConfigurationPage, onFieldBlur: OnFieldBlurHandler | undefined, isReviewMode: boolean, siteId: number | undefined) {
    const Resolved = components[page.pagingKey];

    return Resolved
        ? <Resolved page={page} 
                    onFieldBlur={onFieldBlur}
                    isReviewMode={isReviewMode}
                    siteId={siteId}/>
        : <DefaultConfigurationEntryPage page={page}
                                         onFieldBlur={onFieldBlur}
                                         isReviewMode={isReviewMode}
                                         siteId={siteId}/>
}
