import {ColumnConfig} from "../../admin/shared/Grid";
import {
    reportCol,
    reportDateToString,
    reportMoneyToString,
    reportPipeDelimitedList,
    reportValToOrderLink,
    ReportView,
    reportYesNo
} from "../ReportView";
import {useGetOrderLiabilityReportQuery, useGetOrderMonthEndReportQuery} from "../../../app/apiSlice";
import {OrderLiability} from "./OrderLiability";

export const OrderLiabilityReport = () => {
    const filterFunc = (lowerSearch: string, records: OrderLiability[]) =>
        records.filter(r =>
            r.orderNumber.toString().includes(lowerSearch) ||
            r.orderStatus.toLowerCase().includes(lowerSearch) ||
            r.brand.toLowerCase().includes(lowerSearch) ||
            r.paymentMethod.toLowerCase().includes(lowerSearch) ||
            r.paymentStatus.toLowerCase().includes(lowerSearch) ||
            r.shippingMethod.toLowerCase().includes(lowerSearch) ||
            r.refundTransactionId.toLowerCase().includes(lowerSearch) ||
            r.shippingCity.toLowerCase().includes(lowerSearch) ||
            r.shippingState.toLowerCase().includes(lowerSearch) ||
            r.shippingZipCode.toLowerCase().includes(lowerSearch)
        );

    const columns: ColumnConfig<OrderLiability>[] = [
        reportCol('orderNumber', 'Order #', reportValToOrderLink),
        reportCol('brand', 'Brand'),
        reportCol('source', 'Source'),
        reportCol('orderDate', 'Order Date', reportDateToString),
        reportCol('eventDate', 'Event Date', reportDateToString),
        reportCol('orderStatus', 'Status'),
        reportCol('dateCompleted', 'Date Completed', reportDateToString),
        reportCol('paymentMethod', 'Payment Meth.'),
        reportCol('paymentStatus', 'Payment Status'),
        reportCol('shippingMethod', 'Shipping Meth.'),
        reportCol('orderNetTotal', 'Order Net', reportMoneyToString),
        reportCol('productPrice', 'Product Price', reportMoneyToString),
        reportCol('productAdjustment', 'Product Adj.', reportMoneyToString),
        reportCol('shippingPrice', 'Shipping Price', reportMoneyToString),
        reportCol('shippingAdjustment', 'Shipping Adj.', reportMoneyToString),
        reportCol('ezShieldPrice', 'EZ Shield', reportMoneyToString),
        reportCol('fontPrice', 'Font', reportMoneyToString),
        reportCol('woodCutPrice', 'WoodCut', reportMoneyToString),
        reportCol('customMessagePrice', 'Custom Mesg.', reportMoneyToString),
        reportCol('extraSigLinePrice', 'Extra Sig. Line', reportMoneyToString),
        reportCol('discountAmount', 'Discount', reportMoneyToString),
        reportCol('refundIssued', 'Refund', reportMoneyToString),
        reportCol('refundTransactionId', 'Refund Trans. ID', reportPipeDelimitedList),
        reportCol('handling', 'Handling', reportMoneyToString),
        reportCol('tax', 'Tax', reportMoneyToString),
        reportCol('retailDeliveryFee', 'Retail Delivery Fee', reportMoneyToString),
        reportCol('shippingCost', 'Ship Cost', reportMoneyToString),
        reportCol('shippingCity', 'Ship City'),
        reportCol('shippingState', 'Ship State'),
        reportCol('shippingZipCode', 'Ship Zip'),
    ];

    return <ReportView
        title='Order Liability'
        query={useGetOrderLiabilityReportQuery}
        columns={columns}
        initialSort={{ column: 'orderDate', direction: 'asc'}}
        filterFunc={filterFunc}
        downloadPath='/api/Reports/OrderLiability'
        downloadName='Order Liability'/>
}