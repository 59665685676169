import {Configuration} from "../components/input/configuration/Configuration";
import {FormInputField} from "../components/forms/FormInputField";
import {InputTypeName} from "../components/input/InputType";
import {FormSelect} from "../components/forms/FormSelect";
import {RadioSelectWithBoxedDescription} from "./inputs/RadioSelectWithBoxedDescription";
import {FontSelector} from "../components/fontSelector/FontSelector";
import {AddressInput} from "./inputs/AddressInput";
import {fieldModifierAttributes} from "./FieldModifiers";
import {SymbolSelector} from "./inputs/symbolSelector/SymbolSelector";
import {DefaultConfigurationEntry} from "./inputs/DefaultConfigurationEntry";
import {OnFieldBlurHandler} from "./validation/types";
import {SignatureMessageInput} from "./inputs/SignatureMessageInput";
import {BankName} from "./bankingInformation/BankName";
import {ProtectionSelector} from "./inputs/protectionSelector/ProtectionSelector";
import {PrintingSoftwareSelector} from "./inputs/printingSoftwareSelector/PrintingSoftwareSelector";

interface ConfigurationEntryResolverProps {
	readonly config: Configuration;
	readonly onFieldBlur?: OnFieldBlurHandler;
}

export const ConfigurationEntryResolver = ({config, onFieldBlur = () => void 0}: ConfigurationEntryResolverProps) => {
	return resolve(config, onFieldBlur);
};

const resolveByInputType = (config: Configuration, onFieldBlur: (config: Configuration) => (void | Promise<void>)) => {
	switch (config.inputType.name as InputTypeName) {
		case "Button Single Select":
		case "Radio Select With Boxed Description":
			return (
				<FormSelect name={config.configurationKey}
				            label={config.label}
				            options={config.configurationDetail.options?.map(o => ({
					            text: o.displayName,
					            value: o.value
				            }))}
				            {...fieldModifierAttributes(config)}
				/>
			);
		case "Radio Select With Button Single Select":
			return <RadioSelectWithBoxedDescription config={config}/>
		case "Address":
			return <AddressInput config={config}/>
		case "Font":
			return (
				<FontSelector name={config.configurationKey}
				              label={config.label}
				              options={config.configurationDetail.options ?? []}
				              {...fieldModifierAttributes((config))}
				/>
			);
		case "Hidden":
			return (
				<FormInputField name={config.configurationKey}
				                label={config.label}
				                type="hidden"
				/>
			);
		case "Symbol":
			return <SymbolSelector config={config}/>
		case "Signature Message":
			return <SignatureMessageInput config={config}/>
		case "Protection":
			return <ProtectionSelector config={config}/>
		case "PrintingSoftware":
			return <PrintingSoftwareSelector config={config}/>
		default:
			return null;
	}
};

const resolveByConfigName = (
	configuration: Configuration,
	onFieldBlur: OnFieldBlurHandler
) => {
	if (configuration.configurationKey === 'BankName') {
		return (
			<BankName config={configuration}/>
		)
	}
	return null;
};

const resolve = (config: Configuration, onFieldBlur: OnFieldBlurHandler) => {
	return resolveByConfigName(config, onFieldBlur)
		?? resolveByInputType(config, onFieldBlur)
		?? <DefaultConfigurationEntry config={config} onFieldBlur={onFieldBlur}/>;
};
