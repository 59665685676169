import {ProductConfigurationInputProps} from "./ProductConfigurationInputProps";
import React from "react";
import {Input, Label} from "reactstrap";
import {MediaQueryTypes, useMediaQuery} from "../../app/hooks";
import cn from "classnames";
import classnames from "classnames";
import {Utils} from "../../utils/Utils";
import {ConfigurationOption} from "../../components/input/configuration/ConfigurationOption";
import {useController} from "react-hook-form";
import {isConfigurationOption, ProductInputOption} from "../../components/input/ProductInput";
import {CurrencyFormatter} from "../../utils/CurrencyFormatter";

export const RadioSelectWithBoxedDescription = ({config}: ProductConfigurationInputProps) => {
	const options = config.configurationDetail.options ?? [];

	const {field: {onChange, value}} = useController({
		name: config.configurationKey,
		defaultValue: config.configurationDetail.options?.find(o => o.isDefault)?.value,
	});

	const isSelectedOption = (option: ConfigurationOption) => option.value === value.toString();

	const renderLabel = () => {
		return (
			<Label for={config.configurationKey}>{config.label}</Label>
		);
	};

	const getPriceModification = (option: ProductInputOption) => {
		return isConfigurationOption(option) && option.priceModification ?
			`(+${CurrencyFormatter.format(option.priceModification)})` : "";
	};

	const renderOptions = () => {
		return [...options]
			.sort(Utils.sortBy("sortOrder"))
			.map((option, index) => {
				const classNames = classnames("boxed-radio-option", {"boxed-radio-option-checked": isSelectedOption(option)})
				return (
					<div key={index} className={classNames}>
						<Label>
							<Input type="radio"
							       name={config.configurationKey}
							       checked={isSelectedOption(option)}
							       onChange={() => onChange(option.value.toString())}
							/>
							<span>
								{option.displayName + " " + getPriceModification(option)}
							</span>
						</Label>
					</div>
				);
			});
	};

	const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

	const containerClass = cn(
		isMobile ? '' : 'd-flex d-flex-row',
	);

	const selectorClass = cn(
		isMobile ? '' : 'col-5',
	);

	// If there is only one option, don't render the config at all.
	if (!options || options.length === 1) return null;

	return (
		<>
			{renderLabel()}
			<div className={containerClass}>
				<div className={selectorClass}>
					{renderOptions()}
				</div>
			</div>
		</>
	);
};
