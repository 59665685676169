import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
	useAddProductToCartMutation, useGetCartProductInputValuesQuery,
	useGetConfigurationsForSiteProductQuery, useGetOrderProductInputValuesQuery,
	useGetSiteProductVariantByIdQuery,
	useGetSiteProductVariantQuantityOptionQuery
} from "../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {Configuration, ConfigurationValue} from "../components/input/configuration/Configuration";
import {
	getCurrentPage,
	getProductConfigurationPages,
	goToPage,
	reset,
	setProductConfigurations,
	setQuantityOption,
	setSiteProductVariant
} from "./ProductConfigurationSlice";
import {Stepper} from "../components/stepper/Stepper";
import {ConfigurationEntryForm} from "./ConfigurationEntryForm";
import {ProductConfigurationBanner} from "./ProductConfigurationBanner";
import "./ProductConfigurationPage.css";
import {ReviewPage} from "../components/input/configuration/ConfigurationPaging";
import {AnalyticsTools} from "../utils/AnalyticsHelper";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkSquare} from "@fortawesome/free-solid-svg-icons";
import {showConfirmationModal} from "../components/modal/ModalSlice";
import {selectCurrentConsumerUser} from "../components/user/login/AuthenticationSlice";
import {CartIdType, selectCartId, setCartId} from "../components/cart/CartSlice";
import {selectCartRequest} from "../components/input/ProductInputSlice";
import {ConfigurationOption} from "../components/input/configuration/ConfigurationOption";
import {SimpleToast} from "../components/input/utils/SimpleToast";
import {FieldValues} from "react-hook-form";
import {productDetailsRoute} from "../app/routes";
import {usePersistentFormData} from "./UsePersistProductConfigData";
import {ProductInputValue} from "../components/input/ProductInputValue";
import {valueFromInputValues, ProductConfigurationPage as productConfigPage} from "./model";
import {ProductConfigurationContext} from "./ProductConfigurationContext";
import {isUsingAdminSite} from "../utils/SiteHelper";

export const ProductConfigurationRoute = () => {
	const params = useParams();
	const [searchParams] = useSearchParams();
	const siteProductVariantId = Number.parseInt(params.siteProductVariantId ?? "0");
	const quantityOptionId = Number.parseInt(params.quantityOptionId ?? "0");
	const cartProductId = searchParams.has('cartProductId')
		? Number.parseInt(searchParams.get('cartProductId') ?? '0')
		: undefined;
	const navigate = useNavigate();
	
	return <ProductConfigurationPage siteProductVariantId={siteProductVariantId}
									 quantityOptionId={quantityOptionId}
									 cartProductId={cartProductId}
									 orderProductId={undefined}
									 showCheckoutSignIn={true}
									 onExit={(urlName) => {
										 navigate(productDetailsRoute({
										 	paramProductLookup: urlName
										 }))
									 }}
									 onDone={() => {navigate("/cart")}}
									 isReviewMode={false}
									 onPageChange={(page) => {
										 AnalyticsTools.recordProductConfigPageLoadEvent(page?.name, page.id);}
									 }
	/>;
};

interface ProductConfigurationProps {
	siteProductVariantId: number;
	quantityOptionId: number;
	cartProductId: number | undefined;
	orderProductId: number | undefined;
	siteId?: number;
	showCheckoutSignIn?: boolean;
	onExit?: (urlName: string) => void;
	onDone?: (cartId: CartIdType) => void;
	isReviewMode: boolean;
	onPageChange?: (page: productConfigPage) => void;
}

const DEFAULT_CONFIGURATIONS: Configuration[] = [];
const INITIAL_PRODUCT_INPUT_VALUES: ProductInputValue[] = [];

export const ProductConfigurationPage = ({
											 siteProductVariantId,
											 quantityOptionId,
											 cartProductId,
											 orderProductId,
											 siteId,
											 showCheckoutSignIn = false,
											 onExit = () => {},
											 onDone = () => {},
											 isReviewMode,
											 onPageChange = () => {},
										 }: ProductConfigurationProps) => {
	const dispatch = useAppDispatch();
	const pageList = useAppSelector(getProductConfigurationPages);
	const currentPage = useAppSelector(getCurrentPage);
	const steps = pageList.map(p => ({id: p.id, title: p.name}));
	const currentIndex = steps.findIndex((step) => step.id === currentPage?.id);
	const {clearPersistentFormData} = usePersistentFormData();
	const isAdminSite = isUsingAdminSite();

	const {
		data: siteProductVariant,
		isUninitialized: productVariantUninitialized
	} = useGetSiteProductVariantByIdQuery(siteProductVariantId);

	const {
		data: configurations = DEFAULT_CONFIGURATIONS,
	} = useGetConfigurationsForSiteProductQuery(productVariantUninitialized || !siteProductVariant ? skipToken : {
		siteProductId: siteProductVariant.siteProductId,
		siteProductVariantId,
		quantityOptionId
	});

	const {
		data: quantityOption,
	} = useGetSiteProductVariantQuantityOptionQuery(quantityOptionId!);

	const {
		data: cartProductInputValues = INITIAL_PRODUCT_INPUT_VALUES,
		isFetching: cartProductInputValuesFetching,
		isUninitialized: cartProductInputValuesUninitialized
	} = useGetCartProductInputValuesQuery(cartProductId ?? skipToken);

	const {
		data: orderProductInputValues= INITIAL_PRODUCT_INPUT_VALUES,
		isFetching: orderProductInputValuesFetching,
		isUninitialized: orderProductInputValuesUninitialized
	} = useGetOrderProductInputValuesQuery(orderProductId ?? skipToken);

	useEffect(() => {
		dispatch(setProductConfigurations(configurations));
	}, [configurations]);

	useEffect(() => {
		if (quantityOption) {
			dispatch(setQuantityOption(quantityOption));
		}
	}, [quantityOption]);

	useEffect(() => {
		dispatch(setSiteProductVariant(siteProductVariant));
	}, [siteProductVariant]);

	// Capture configuration page info for site analytics.
	useEffect(() => {
		if (currentPage !== undefined) {
			onPageChange(currentPage);
		}
		if (isReviewMode) {
			if (currentPage !== pageList[pageList.length - 1]) {
				dispatch(goToPage(pageList.length - 1));
			}
		}
	}, [currentPage]);

	// Bug 3050: Editing after adding to cart keeps the same values
	// https://dev.azure.com/MainStreetInc/Main%20Street/_workitems/edit/3050
	// This has the effect of clearing the persisted data when the page is unmounted
	// only if there is a cartProductId specified. This is to force it to load
	// the saved values.
	useEffect(() => {
		return () => {
			if (cartProductId) {
				clearPersistentFormData();
			}
		}
	}, [cartProductId]);

	useEffect(() => {
		return () => {
			if (isAdminSite) {
				clearPersistentFormData();
			}
		};
	}, []);

	function exitProductConfig() {
		dispatch(showConfirmationModal({
			title: 'Exit Product Configuration?',
			content: 'Are you sure you want to delete all progress? This action cannot be undone.',
			affirmText: 'Exit Product Configuration',
			cancelText: 'Stay here',
			onConfirm: () => {
				dispatch(reset());
				onExit(siteProductVariant?.urlName ?? '');
			}
		}));
	}

	// Add to Cart.

	const handleSubmit = async (fieldValues: FieldValues) => {
		await addToCart(fieldValues);

		// Reset the product configuration store.
		dispatch(reset());

		// Clear form data from persistent storage.
		clearPersistentFormData();
	};

	const spvId = Number.parseInt(String(siteProductVariantId!));
	const qoId = Number.parseInt(String(quantityOptionId!));
	const consumerId = useAppSelector(selectCurrentConsumerUser)?.id;
	const cartId: CartIdType = useAppSelector(selectCartId);

	const orderEditCartId = useAppSelector(state => state.cart.orderEditCart?.cartId);
	const cartRequest = useAppSelector(s => selectCartRequest(s, spvId, qoId, consumerId, cartId));

	const [addProductToCart] = useAddProductToCartMutation();
	const [toastIsOpen, setToastIsOpen] = useState(false);

	// Builds the configuration collection from the form input values.
	const getConfigurationValues = (fieldValues: FieldValues) => {
		let configurationValues: ConfigurationValue[] = [];

		// Loop through supplied form values.
		for (const key in fieldValues) {
			if (fieldValues.hasOwnProperty(key) && fieldValues[key]) {
				// Match the form value to a configuration.
				const config = configurations.find((c) => c.configurationKey === key);
				if (config && config.id > 0) {
					// Stringify any object values.
					const stringValue = typeof fieldValues[key] === "object"
						? JSON.stringify(fieldValues[key])
						: fieldValues[key];
					// Add to the collection.
					configurationValues.push({
						configurationKey: key,
						configurationId: config?.id ?? 0,
						stringValue: stringValue,
						configurationDetailId: config.configurationDetail.id,
						configurationOptionId: getConfigurationOptionId(config, stringValue)
					});
				}
			}
		}
		return configurationValues;
	};

	const getConfigurationOptionId = (config: Configuration, value: string) => {
		const options = getFurthestChildOptions(config);
		if (options && options.length > 0) {
			const option = options.find((o) => o.value === value);
			if (option) {
				return option.id;
			}
		}
		return undefined;
	};

	// Recursively find the furthest descendant options.
	const getFurthestChildOptions = (config: Configuration) => {
		const getLeafOptions = (options: ConfigurationOption[]) => {
			let leafOptions: ConfigurationOption[] = [];
			for (const option of options) {
				if (option.childConfigurationOptions && option.childConfigurationOptions.length > 0) {
					leafOptions = leafOptions.concat(getLeafOptions(option.childConfigurationOptions));
				} else {
					leafOptions.push(option);
				}
			}
			return leafOptions;
		}

		const options = config.configurationDetail.options;
		if (options && options.length > 0) {
			return getLeafOptions(options);
		}
	};

	const addToCart = async (fieldValues: FieldValues) => {
		try {
			// Add configuration data to the payload.
			cartRequest.configurationValues = getConfigurationValues(fieldValues);

			// Send the request.
			const response = await addProductToCart({...cartRequest, cartId: orderEditCartId ?? cartId, cartProductId}).unwrap();
			dispatch(setCartId(response.cartId));

			// Capture the event for analytics.
			if (response.cartProduct) {
				AnalyticsTools.recordAddToCartEvent(response.cartProduct);
			}

			onDone(response.cartId);
		} catch (err: any) {
			console.info(err);
			setToastIsOpen(true);
		}
	};
	const renderForm =
		configurations && configurations.length > 0
		&& (
			(cartProductInputValuesUninitialized || !cartProductInputValuesFetching)
			&&
			(orderProductInputValuesUninitialized || !orderProductInputValuesFetching)
		)

	function getInputValues(){
		if (!cartProductInputValuesUninitialized) {
			return cartProductInputValues;
		}
		if (!orderProductInputValuesUninitialized) {
			return orderProductInputValues;
		}
		return INITIAL_PRODUCT_INPUT_VALUES;
	}
	
	return (
		<div className={"product-configuration-container"}>
			{!isReviewMode &&
				<Stepper steps={steps} currentStepIndex={currentIndex}/>
			}
			<div className={"product-configuration-content-container"}>
				{siteProductVariant && currentPage?.pagingKey !== ReviewPage.pagingKey &&
                    <ProductConfigurationBanner
						siteProductVariant={siteProductVariant}
						showCheckoutSignIn={showCheckoutSignIn}
					/>
				}
				<div className="border p-3 bg-white">
					{renderForm &&
						<ProductConfigurationContext.Provider value={{ cartProductId }}>
							<ConfigurationEntryForm configs={configurations}
													value={valueFromInputValues(getInputValues())}
													onSubmit={fieldValues => handleSubmit(fieldValues)}
													isReviewMode={isReviewMode}
													siteId={siteId}
							/>
						</ProductConfigurationContext.Provider>
					}
				</div>
				<div>
					{!isReviewMode &&
						<Button onClick={exitProductConfig} className="bg-transparent border-0 text-primary">
							<FontAwesomeIcon flip="horizontal" icon={faExternalLinkSquare}/> Exit Product Configuration
						</Button>
					}
				</div>
			</div>
			<div className="floating-toast">
				<SimpleToast isOpen={toastIsOpen}
				             toastText="Failed to add product to cart. Please contact Customer Service for assistance."
				             dismissToast={() => setToastIsOpen(false)}
				             isErrorToast={true}
				/>
			</div>
		</div>
	);
};
