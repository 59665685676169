import { useAppSelector } from "../../app/hooks";
import {useGetProductPreviewQuery} from "../../app/apiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {Alert, Row, Spinner} from "reactstrap";
import { useTranslation } from "react-i18next";
import {ImageZoom} from "../../components/image/zoom/ImageZoom";
import React, {useEffect, useMemo, useState} from "react";
import {CartIdType} from '../../components/cart/CartSlice';
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {Configuration, ConfigurationValue} from "../../components/input/configuration/Configuration";
import {useFormContext} from "react-hook-form";
import {
    getProductConfigurationPages,
} from "../ProductConfigurationSlice";
import {GetPreviewRequest} from "../../components/check/GetPreviewRequest";
import {allConfigs} from "../model";
import {useConfigsWithPriceChange} from "../UseConfigsWithPriceChange";
import {InfoLabel} from "../inputs/InfoLabel";
import cn from "classnames";
import {FULL_STORY_SECURE_ELEMENT_CLASS} from "../../components/input/ProductInput";

interface CheckReviewProps {
  sku: string
  siteId?: number
  siteProductVariantId: number
  cartId: CartIdType
}

export const CheckReview = ({
	sku,
	siteId,
	siteProductVariantId,
	cartId,
}: CheckReviewProps) => {
	const { t } = useTranslation();

    const [previewRequest, setPreviewRequest] = useState<GetPreviewRequest>();

    const {subTotal} = useConfigsWithPriceChange();
    
    const { data: previewImage, error, isFetching}
      = useGetProductPreviewQuery(previewRequest ?? skipToken);

    const {getValues} = useFormContext();

    const myPages = useAppSelector(getProductConfigurationPages);

    function getValue(config: Configuration) {
        const configValue = getValues(config.configurationKey);
        if (config.inputType.name === 'Address') {
            return JSON.stringify(configValue);
        }
        return configValue;
    }

    useEffect(() => {
        const configs = allConfigs(myPages);

        const request: GetPreviewRequest = {
            sku,
            siteId,
            cartId,
            siteProductVariantId,
            configurationValues: configs
                .filter(pi => getValues(pi.configurationKey))
                .map(pi => ({
                    configurationId: pi.id,
                    configurationDetailId: pi.configurationDetail.id,
                    stringValue: getValue(pi),
                } as ConfigurationValue)),
        }

        setPreviewRequest(request);
    }, [subTotal])

    const alert = useMemo(() => {
        const statusCode = error && (error as FetchBaseQueryError)?.status
        if (!error) {
          return undefined;
        }
        else if (statusCode === 408) {
          return t('cart.previewTimeout');
        }
        else if (statusCode === 422) {
          return t('cart.customPrintingServiceError');
        }
        else {
          return t('cart.previewError');
        }
    }, [error, t]);

    const previewImageClassName = cn(
        FULL_STORY_SECURE_ELEMENT_CLASS,
        {
            'loading': isFetching
        }
    );
    
    return (
        <div className="d-flex flex-column">

          <h3 className="check-preview-title">Your Preview Image</h3>
          {isFetching &&
            <div className="row py-5 w-100 align-items-center justify-content-center d-flex">
              <Spinner>Loading</Spinner>
            </div>
          }
          {!isFetching && error && alert &&
              <Alert color='warning'>{alert}</Alert>
          }
          {!isFetching && previewImage && !error &&
              <div id="PreviewImage" className={previewImageClassName}>
                <ImageZoom title='Personalized Preview' imageUrl={previewImage} />
              </div>
          }

            <Row className="m-1">
                <InfoLabel>{t('bank.disclaimer.title')}{' '}{t('bank.disclaimer.body')}</InfoLabel>
            </Row>
        </div>
    );
}