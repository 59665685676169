import {PropsWithChildren} from "react";
import cn from "classnames";
import {MediaQueryTypes, useMediaQuery} from "../../app/hooks";
import {Card, CardBody, CardTitle} from "reactstrap";
import {isEmpty} from "lodash";
import {InfoLabel} from "../inputs/InfoLabel";

interface ReviewPanelProps extends PropsWithChildren {
    readonly title: string;
    readonly infoLabel?: string;
    readonly showInfoLabel?: boolean;
    readonly className?: string;
    readonly cardClassName?: string;
    readonly cardTitleClassName?: string;
    readonly cardBodyClassName?: string;
}

export function ReviewPanel({
                                children,
                                title,
                                infoLabel,
                                showInfoLabel = false,
                                className,
                                cardClassName: providedCardClassName,
                                cardTitleClassName: providedCardTitleClassName,
                                cardBodyClassName: providedCardBodyClassName,
                            }: ReviewPanelProps) {
    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

    const containerClassName = cn(
        'm-0',
        'mb-4',
        {
            'm-1': isMobile,
            'ms-4': !isMobile,
            'me-5': !isMobile,
        },
        className,
    );

    const cardClassName = cn(
        'border',
        'border-dark',
        'border-1',
        'p-2',
        'bg-secondary',
        'bg-opacity-10',
        providedCardClassName
    );

    const cardTitleClassName = cn(
        'text-secondary',
        providedCardTitleClassName
    );

    const cardBodyClassName = cn(
        providedCardBodyClassName
    );
    
    return (
        <div className={containerClassName}>
            <Card className={cardClassName}>
                <CardTitle className={cardTitleClassName}>
                    {title}:
                </CardTitle>
                <CardBody className={cardBodyClassName}>
                    {children}
                </CardBody>
            </Card>
            {showInfoLabel && !isEmpty(infoLabel) &&
                <InfoLabel>{infoLabel}</InfoLabel>
            }
        </div>
    );
}