import {Button, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import {useFormContext} from "react-hook-form";
import {useContext} from "react";
import {ProductConfigurationContext, ProductConfigurationContextType} from "../ProductConfigurationContext";

interface AddToCartButtonProps {
	className?: string;
}

export const AddToCartButton = ({className}: AddToCartButtonProps) => {

	const { cartProductId } = useContext(ProductConfigurationContext) as ProductConfigurationContextType;
	const {formState: {isSubmitting}} = useFormContext();

	return (
		<Button type="submit" className={className} disabled={isSubmitting}>
			{isSubmitting && <Spinner/>}
			<FontAwesomeIcon icon={faCartPlus}/>
			{(cartProductId ? "Update Product in Cart" : "Add to Cart")}
		</Button>
	)
};
